import React from 'react';
import {
  Button,
  Card,
  CardBody,
  CardText,
} from "reactstrap";
import { Edit, Trash2, } from "react-feather";


const Audio = (props) => {

  const { audio, name, onChange, onDelete, } = props;
  console.log(audio);


  return (
    <Card style={{ width: 350, margin: 5, }}>
      <CardBody>
        <audio controls>
          <source src={audio} type="audio/mpeg" />
          Your browser does not support the audio element.
        </audio>

        <CardText className="text-center">
          {name}
        </CardText>

        <div className="d-flex align-items-center" style={{ float: 'right', }}>
          <Button className="p-0 mr-1" color="none" onClick={onChange}>
            <Edit className="align-middle" size={18} />
          </Button>
          <Button className="p-0" color="none" onClick={onDelete}>
            <Trash2 className="align-middle text-danger" size={18} />
          </Button>
        </div>

      </CardBody>
    </Card>
  );
}

export default Audio;