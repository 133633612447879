import React from "react";
import { connect } from "react-redux";

const Wrapper = ({ children }) => (
  <div className={"wrapper "}>
    {children}
  </div>
);

export default connect(store => ({
  layout: store.layout
}))(Wrapper);
