import * as types from "../constants";

const initialState = {
  user: null,
  session: null,
};

export default function reducer(state = initialState, actions) {
  switch (actions.type) {
    case types.SESSION_LOGIN:
      return {
        ...state,
        user: actions.user,
        session: actions.session,
      };

    case types.SESSION_LOGOUT:
      return {
        ...state,
        user: null,
        session: null,
      };

    default:
      return state;
  }
}
