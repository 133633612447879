import React, { Fragment, useEffect, useState, useRef } from 'react';
import { useSelector } from 'react-redux';
import { GoogleSpreadsheet } from 'google-spreadsheet';
import {
	Button,
	Container,
	Row,
	Col,
	Input,
	Spinner,
	FormGroup,
	Label,
	UncontrolledButtonDropdown,
	DropdownItem,
	DropdownMenu,
	DropdownToggle,
} from 'reactstrap';
import Select from 'react-select';
import { useHistory } from 'react-router-dom';
import fileDialog from 'file-dialog';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlusCircle } from '@fortawesome/free-solid-svg-icons';
import { Edit, RefreshCcw } from 'react-feather';

import { ApiService } from '../../../services/apiService';
import { showToastr } from '../../../services/themeService';
import Image from '../../../components/Image';
import Audio from '../../../components/Audio';
import { config } from '../../../config';
import GoogleSheetKey from '../../../google_sheet_key.json';

const sheets = [
	{ value: 'en', label: 'EN' },
	{ value: 'es', label: 'ES' },
	{ value: 'fr', label: 'FR' },
	{ value: 'de', label: 'DE' },
	{ value: 'en-es', label: 'EN ES' },
	{ value: 'en-fr', label: 'EN FR' },
	{ value: 'en-de', label: 'EN DE' },
	{ value: 'es-fr', label: 'ES FR' },
	{ value: 'es-de', label: 'ES DE' },
	{ value: 'fr-de', label: 'FR DE' },
	{ value: 'en-es-fr', label: 'EN ES FR' },
	{ value: 'en-es-de', label: 'EN ES DE' },
	{ value: 'en-fr-de', label: 'EN FR DE' },
	{ value: 'es-fr-de', label: 'ES FR DE' },
	{ value: 'en-es-fr-de', label: 'EN ES FR DE' },
];

const modeOptions = [
	{ value: 0, label: 'None' },
	{ value: 1, label: 'Click' },
	{ value: 2, label: 'Listen' },
	{ value: 3, label: 'Type' },
	{ value: 4, label: 'Speak' },
	{ value: 5, label: 'MTC' },
	{ value: 6, label: 'Match' },
];

const translationOptions = [
	{ value: 1, label: 'L1>L2' },
	{ value: 2, label: 'L2>L1' },
];

const customStyles = {
	container: (provided, state) => ({
		...provided,
		width: 120,
		marginRight: 15,
	}),
};

const ActivityEdit = (props) => {
	const history = useHistory();
	const { session } = useSelector((state) => state.session);

	const { id } = props.match.params;

	const textToSpeech = async (exercise, voice) => {
		let sentence = '';
		if (exercise.mode === 5) {
			sentence = exercise[language.code].split('/')[0];
		} else {
			sentence = exercise[language.code];
		}
		const text = sentence.replace(/ *\([^)]*\) */g, '').replace(/\*/g, '');

		if (voice.provider === 1) {
			return await new Promise((resolve, reject) => {
				const speechConfig = config.SDK.SpeechConfig.fromSubscription(
					'35f15be4a09443a4bcb356c04783a7d7',
					'westeurope'
				);
				speechConfig.speechSynthesisVoiceName = voice.voice_name;
				var player = new config.SDK.SpeakerAudioDestination();

				const synthesizer = new config.SDK.SpeechSynthesizer(
					speechConfig,
					null
				);

				synthesizer.speakTextAsync(
					text,
					(result) => {
						if (!result.audioData) {
							resolve('error');
						} else {
							player.pause();
							synthesizer.close();

							resolve(
								JSON.stringify(new Int8Array(result.audioData))
							);
						}
					},
					(error) => {
						console.log(text, error);
						synthesizer.close();

						resolve('error');
					}
				);
			});
		} else if (voice.provider === 2) {
			return await new Promise((resolve, reject) => {
				var speechParams = {
					OutputFormat: 'mp3',
					SampleRate: '16000',
					Text: text,
					TextType: 'text',
					VoiceId: voice.voice,
				};

				var polly = new config.AWS.Polly({ apiVersion: '2016-06-10' });
				var signer = new config.AWS.Polly.Presigner(
					speechParams,
					polly
				);

				signer.getSynthesizeSpeechUrl(
					speechParams,
					function (error, url) {
						if (error) {
							console.log(text, error);
							resolve('error');
						} else {
							resolve(url);
						}
					}
				);
			});
		} else {
			return await new Promise((resolve, reject) => {
				resolve('');
			});
		}
	};

	const getImageData = async (file) => {
		return await new Promise((resolve, reject) => {
			var reader = new FileReader();

			reader.onload = (e) => {
				resolve([reader.result]);
			};

			reader.readAsDataURL(file);
		});
	};

	const [activity, setActivity] = useState(null);
	const [exercises, setExercises] = useState([]);
	const [levels, setLevels] = useState([]);
	const [chapters, setChapters] = useState([]);
	const [characters, setCharacters] = useState([]);
	const [voices, setVoices] = useState([]);
	const [languageOptions, setLanguageOptions] = useState([]);
	const [levelOptions, setLevelOptions] = useState([]);
	const [chapterOptions, setChapterOptions] = useState([]);
	const [sheetOptions, setSheetOptions] = useState([]);
	const [language, setLanguage] = useState(null);
	const [level, setLevel] = useState(null);
	const [chapter, setChapter] = useState(null);
	const [sheet, setSheet] = useState(null);
	const [sync, setSync] = useState(true);
	const [images, setImages] = useState([]);
	const [audios, setAudios] = useState([]);
	const [waiting, setWaiting] = useState(false);
	const audioRef = useRef([]);
	const [updatedExercises, setUpdatedExercises] = useState([]);

	useEffect(() => {
		ApiService.getActivity(session, id)
			.then((response) => response.json())
			.then((data) => {
				if (data && data.message === 'Unauthenticated.') {
					history.push('/admin');

					return;
				}

				const {
					activity,
					languages,
					levels,
					chapters,
					characters,
					voices,
				} = data;

				setLevels(levels);
				setChapters(chapters);
				setCharacters(characters);
				setVoices(voices);
				setExercises(activity ? activity.exercises.slice() : null);

				if (activity) {
					audioRef.current = new Array(activity.exercises.length);
					// audioRef.current = Array(activity.exercises).fill().map((_, i) => audioRef.current[i] || createRef());
				}

				if (id && !activity) {
					return;
				}

				let languageOptions = [];
				languages.map((language) => {
					languageOptions.push({
						value: language.id,
						code: language.code,
						label: language.title,
					});
				});

				setLanguageOptions(languageOptions);

				let levelOptions = [];
				let chapterOptions = [];

				if (!activity) {
					setLanguage(languageOptions[0]);

					setSheetOptions(
						sheets.filter((sheet) =>
							sheet.value.includes(languageOptions[0].code)
						)
					);

					setSheet(
						sheets.filter((sheet) =>
							sheet.value.includes(languageOptions[0].code)
						)[0]
					);

					levels
						.filter(
							(level) =>
								level.language_id === languageOptions[0].value
						)
						.map((level) => {
							levelOptions.push({
								value: level.id,
								label: level.level,
							});
						});

					setLevelOptions(levelOptions);

					setLevel(levelOptions[0]);

					chapters
						.filter(
							(chapter) =>
								chapter.level_id === levelOptions[0].value
						)
						.map((chapter) => {
							chapterOptions.push({
								value: chapter.id,
								label: chapter.en,
							});
						});

					setChapterOptions(chapterOptions);

					setChapter(chapterOptions[0]);

					setActivity({
						chapter: chapterOptions[0].value,
						title_en: '',
						title_es: '',
						title_fr: '',
						title_de: '',
						image: '',
						google_sheet_url: '',
						sheet_name: '',
					});

					return;
				}

				setActivity(activity);

				const language = languages.find(
					(language) => language.id === activity.language.id
				);
				setLanguage(
					languageOptions.find(
						(languageOption) => languageOption.value === language.id
					)
				);

				levels
					.filter((level) => level.language_id === language.id)
					.map((level) => {
						levelOptions.push({
							value: level.id,
							label: level.level,
						});
					});

				setLevelOptions(levelOptions);

				const level = activity.level.id;
				setLevel(
					levelOptions.find(
						(levelOption) => levelOption.value === level
					)
				);

				chapters
					.filter((chapter) => chapter.level_id === level)
					.map((chapter) => {
						chapterOptions.push({
							value: chapter.id,
							label: chapter[language.code],
						});
					});

				setChapterOptions(chapterOptions);

				const chapter = activity.chapter.id;
				setChapter(
					chapterOptions.find(
						(chapterOption) => chapterOption.value === chapter
					)
				);

				if (activity.google_sheet_url) {
					let sheetOptions = [];
					sheetOptions = sheets.filter((sheet) =>
						sheet.value.includes(activity.language.code)
					);
					setSheetOptions(sheetOptions);

					setSheet(
						sheetOptions.find(
							(sheetOption) =>
								sheetOption.label === activity.sheet_name
						)
					);
				}
			})
			.catch((err) => {
				console.error(err);
			});
	}, []);

	useEffect(() => {
		if (!exercises) return;

		const tmpExercises = JSON.parse(JSON.stringify(exercises));
		setUpdatedExercises(tmpExercises);
	}, [exercises]);

	const handleSave = async () => {
		if (sync) {
			if (!activity.google_sheet_url || !sheet) {
				showToastr(
					'warning',
					'Fields Required',
					'Please input sheet info.'
				);

				return;
			}

			setWaiting(true);

			const doc = new GoogleSpreadsheet(activity.google_sheet_url);

			try {
				await doc.useServiceAccountAuth({
					client_email: GoogleSheetKey.client_email,
					private_key: GoogleSheetKey.private_key,
				});
				// loads document properties and worksheets
				await doc.loadInfo();

				const sheetData = doc.sheetsByTitle[sheet.label];
				if (!sheetData) {
					showToastr('warning', 'No Data', 'No data in sheet.');
					setWaiting(false);

					return;
				}
				const rows = await sheetData.getRows();

				const imageCount = rows.filter((row) =>
					row.TYPE.includes('image')
				).length;
				if (
					imageCount === 0 ||
					imageCount !==
						images.filter((image) =>
							image.file.name.includes('image')
						).length
				) {
					showToastr(
						'error',
						'Images Error',
						'Image count incorrect.'
					);
					setWaiting(false);

					return;
				}

				let updatedActivity = {};
				updatedActivity.chapter_id = chapter.value;
				const title = rows.find((row) => row.TYPE === 'title');
				if (title === undefined) {
					showToastr('error', 'Sync Error', 'No Title.');
					setWaiting(false);
					return;
				}

				updatedActivity.title_en = title.EN;
				updatedActivity.title_es = title.ES;
				updatedActivity.title_fr = title.FR;
				updatedActivity.title_de = title.DE;
				updatedActivity.google_sheet_url = activity.google_sheet_url;
				updatedActivity.sheet_name = sheet.label;

				const mainImage = images.find((image) =>
					image.file.name.includes('image1.')
				);

				const mainImage2 = images.find((image) =>
					image.file.name.includes('image2.')
				);

				const mainImage3 = images.find((image) =>
					image.file.name.includes('image3.')
				);

				const mainImage4 = images.find((image) =>
					image.file.name.includes('image4.')
				);

				const mainImage5 = images.find((image) =>
					image.file.name.includes('image5.')
				);

				if (mainImage === undefined) {
					showToastr('error', 'Sync Error', 'No Activity Image.');
					setWaiting(false);

					return;
				}
				updatedActivity.image = mainImage.file;

				if (mainImage2 !== undefined)
					updatedActivity.image2 = mainImage2.file;

				if (mainImage3 !== undefined)
					updatedActivity.image3 = mainImage3.file;

				if (mainImage4 !== undefined)
					updatedActivity.image4 = mainImage4.file;

				if (mainImage5 !== undefined)
					updatedActivity.image5 = mainImage5.file;
				const male1Image = images.find(
					(image) => image.file.name.substring(0, 6) === 'male1.'
				);
				const male2Image = images.find(
					(image) => image.file.name.substring(0, 6) === 'male2.'
				);
				const female1Image = images.find(
					(image) => image.file.name.substring(0, 8) === 'female1.'
				);
				const female2Image = images.find(
					(image) => image.file.name.substring(0, 8) === 'female2.'
				);
				const acapelamImage = images.find(
					(image) => image.file.name.substring(0, 9) === 'acapelam.'
				);
				const acapelafImage = images.find(
					(image) => image.file.name.substring(0, 9) === 'acapelaf.'
				);

				if (rows.find((row) => row.TYPE === 'male1') && !male1Image) {
					showToastr('error', 'Images Error', 'No Image for male1.');
					setWaiting(false);

					return;
				}

				if (rows.find((row) => row.TYPE === 'male2') && !male2Image) {
					showToastr('error', 'Images Error', 'No Image for male2.');
					setWaiting(false);

					return;
				}

				if (
					rows.find((row) => row.TYPE === 'female1') &&
					!female1Image
				) {
					showToastr(
						'error',
						'Images Error',
						'No Image for female1.'
					);
					setWaiting(false);

					return;
				}

				if (
					rows.find((row) => row.TYPE === 'female2') &&
					!female2Image
				) {
					showToastr(
						'error',
						'Images Error',
						'No Image for female2.'
					);
					setWaiting(false);

					return;
				}

				if (
					rows.find((row) => row.TYPE === 'acapelam') &&
					!acapelamImage
				) {
					showToastr(
						'error',
						'Images Error',
						'No Image for acapelam.'
					);
					setWaiting(false);

					return;
				}

				if (
					rows.find((row) => row.TYPE === 'acapelaf') &&
					!acapelafImage
				) {
					showToastr(
						'error',
						'Images Error',
						'No Image for acapelaf.'
					);
					setWaiting(false);

					return;
				}

				const acapelamAudios = audios.filter((audio) =>
					audio.name.includes('acapelam')
				);
				const acapelafAudios = audios.filter((audio) =>
					audio.name.includes('acapelaf')
				);

				if (
					rows.filter((row) => row.TYPE === 'acapelam').length !==
					acapelamAudios.length
				) {
					showToastr(
						'error',
						'Audios Error',
						'Audios for acapelam count incorrect.'
					);
					setWaiting(false);

					return;
				}

				if (
					rows.filter((row) => row.TYPE === 'acapelaf').length !==
					acapelafAudios.length
				) {
					showToastr(
						'error',
						'Audios Error',
						'No Audio for acapelaf.'
					);
					setWaiting(false);

					return;
				}

				updatedActivity.image_male1 = male1Image ? male1Image.file : '';
				updatedActivity.image_male2 = male2Image ? male2Image.file : '';
				updatedActivity.image_female1 = female1Image
					? female1Image.file
					: '';
				updatedActivity.image_female2 = female2Image
					? female2Image.file
					: '';
				updatedActivity.image_acapelam = acapelamImage
					? acapelamImage.file
					: '';
				updatedActivity.image_acapelaf = acapelafImage
					? acapelafImage.file
					: '';
				updatedActivity.link =
					rows.find(
						(row) =>
							row[`LINK_${language.code.toUpperCase()}`] !== ''
					)?.[`LINK_${language.code.toUpperCase()}`] ?? '';

				let exercises = [];

				const filterRows = rows.filter(
					(row) => row.TYPE !== 'title' && row.TYPE !== 'image1'
					// row.TYPE != 'image2'
				);

				let i_m = 1;
				let i_f = 1;

				const getAudio = async (row) => {
					let exercise = {};
					exercise.id = 0;

					if (row.EN !== undefined) {
						const sentences_en = row.EN.split('//');
						const sentences_es = row.ES.split('//');
						const sentences_fr = row.FR.split('//');
						const sentences_de = row.DE.split('//');
						exercise.en = sentences_en[0];
						exercise.es = sentences_es[0];
						exercise.fr = sentences_fr[0];
						exercise.de = sentences_de[0];
						let literals = '';
						if (
							sentences_en.length > 1 ||
							sentences_es.length > 1 ||
							sentences_fr.length > 1 ||
							sentences_de.length > 1
						) {
							literals +=
								sentences_en.length > 1 ? sentences_en[1] : '';
							literals += '*&*';
							literals +=
								sentences_es.length > 1 ? sentences_es[1] : '';
							literals += '*&*';
							literals +=
								sentences_fr.length > 1 ? sentences_fr[1] : '';
							literals += '*&*';
							literals +=
								sentences_de.length > 1 ? sentences_de[1] : '';
						}
						exercise.literals = literals;
						exercise.provider = 0;
						exercise.provider_1 = 0;
					}

					if (row.MODE === 'click') {
						exercise.mode = 1;
					} else if (row.MODE === 'listen') {
						exercise.mode = 2;
					} else if (row.MODE === 'type') {
						exercise.mode = 3;
					} else if (row.MODE === 'speak') {
						exercise.mode = 4;
					} else if (row.MODE === 'mtc') {
						exercise.mode = 5;
					} else if (row.MODE === 'match') {
						exercise.mode = 6;
					} else {
						exercise.mode = 0;
					}

					if (row.CLN) {
						exercise.cln = row.CLN;
					} else {
						exercise.cln = null;
					}

					// Add the LINk_lngcode
					if (row.LINK_EN) {
						exercise.link_en = row.LINK_EN;
					} else {
						exercise.link_en = null;
					}

					if (row.LINK_ES) {
						exercise.link_es = row.LINK_ES;
					} else {
						exercise.link_es = null;
					}

					if (row.LINK_FR) {
						exercise.link_fr = row.LINK_FR;
					} else {
						exercise.link_fr = null;
					}

					if (row.LINK_DE) {
						exercise.link_de = row.LINK_DE;
					} else {
						exercise.link_de = null;
					}
					// End Add the Link langcode

					if (
						exercise.mode !== 0 ||
						row.TYPE === 'text' ||
						row.TYPE === 'options'
					) {
						if (row.TRL === 'L2>L1') {
							exercise.translation = 2;
						} else {
							exercise.translation = 1;
						}
					} else {
						exercise.translation = 0;
					}

					if (row.TYPE.includes('image')) {
						const id = row.TYPE.substring(5);
						exercise.type = 0;
						exercise.character = 0;
						exercise.file = images.find((image) =>
							image.file.name.includes(`image${id}.`)
						)?.file;
					} else if (row.TYPE === 'text') {
						exercise.type = 1;
						exercise.character = 0;
						exercise.file = '';
					} else if (row.TYPE === 'options') {
						exercise.type = 4;
						exercise.character = 0;
						exercise.file = 0;
					} else if (row.TYPE === 'user') {
						exercise.type = 2;
						exercise.character = 0;

						const character_m = characters.find(
							(character) => character.name === 'user1'
						);
						if (character_m === undefined) {
							showToastr(
								'error',
								'Sync Error',
								'No Character for User(Male).'
							);
							setWaiting(false);

							return;
						}

						const voiceId_m = character_m['voice_' + language.code];
						const voice_m = voices.find(
							(voice) => voice.id === voiceId_m
						);
						if (voice_m === undefined) {
							showToastr(
								'error',
								'Sync Error',
								'No Voice for Character(Male).'
							);
							setWaiting(false);

							return;
						}

						const audio_m = await textToSpeech(exercise, voice_m);
						if (audio_m === 'error') {
							showToastr(
								'error',
								'Sync Error',
								`Audio error for '${exercise[language.code]}'!`
							);
						}

						exercise.provider = voice_m.provider;
						exercise.file = audio_m;

						const character_f = characters.find(
							(character) => character.name === 'user2'
						);
						if (character_f === undefined) {
							showToastr(
								'error',
								'Sync Error',
								'No Character for User(Female).'
							);
							setWaiting(false);

							return;
						}

						const voiceId_f = character_f['voice_' + language.code];
						const voice_f = voices.find(
							(voice) => voice.id === voiceId_f
						);
						if (voice_f === undefined) {
							showToastr(
								'error',
								'Sync Error',
								'No Voice for Character(Female).'
							);
							setWaiting(false);

							return;
						}

						const audio_f = await textToSpeech(exercise, voice_f);
						if (audio_f === 'error') {
							showToastr(
								'error',
								'Sync Error',
								`Audio error for '${exercise[language.code]}'!`
							);
						}

						exercise.provider_1 = voice_f.provider;
						exercise.file_1 = audio_f;
					} else {
						exercise.type = 3;

						const character = characters.find(
							(character) =>
								character.name.toLowerCase() ===
								row.TYPE.toLowerCase()
						);

						if (character === undefined) {
							showToastr(
								'error',
								'Sync Error',
								'No Character for ' + row.TYPE
							);
							setWaiting(false);

							return;
						}

						exercise.character = character.id;

						if (character.name.includes('acapela')) {
							exercise.provider = 3;
							if (character.name === 'acapelam') {
								exercise.file = audios.find((audio) =>
									audio.name.includes(`acapelam${i_m}.`)
								);

								i_m++;
							} else if (character.name === 'acapelaf') {
								exercise.file = audios.find((audio) =>
									audio.name.includes(`acapelaf${i_f}.`)
								);

								i_f++;
							}
						} else {
							const voiceId = character['voice_' + language.code];
							const voice = voices.find(
								(voice) => voice.id === voiceId
							);
							if (voice === undefined) {
								showToastr(
									'error',
									'Sync Error',
									'No Voice for Character(' +
										character.name +
										').'
								);
								setWaiting(false);

								return;
							}

							const audio = await textToSpeech(exercise, voice);
							if (audio === 'error') {
								showToastr(
									'error',
									'Sync Error',
									`Audio error for '${
										exercise[language.code]
									}'!`
								);
							}

							exercise.provider = voice.provider;
							exercise.file = audio;
						}
					}

					exercises.push(exercise);
				};

				await filterRows.reduce(async (previous, row) => {
					await previous;
					return getAudio(row);
				}, Promise.resolve(null));

				console.log('before updating api exercise', exercises);

				ApiService.updateActivity(
					session,
					id,
					updatedActivity,
					exercises,
					sync
				)
					.then((data) => {
						setWaiting(false);

						history.push('/admin/journeys/activities', {
							created: id ? false : true,
						});
						return;
					})
					.catch((err) => {
						console.error(err);

						setWaiting(false);
					});
			} catch (e) {
				console.error('Error: ', e);
				if (e === 'error') {
					showToastr('error', 'Sync Error', `Audio error!`);
				} else {
					showToastr('error', 'Sync Error', e);

					setWaiting(false);

					return;
				}
			}
		} else {
			if (
				!activity.title_en ||
				!activity.title_es ||
				!activity.title_fr ||
				!activity.title_de
			) {
				showToastr('warning', 'Fields Required', 'Please input title.');

				return;
			}

			setWaiting(true);

			let tmpExercises = [];
			let failed = false;

			const getAudio = async (inExercise) => {
				let exercise = inExercise;
				const oriExercise = exercises.find(
					(exercise) => exercise.id === inExercise.id
				);

				let bChanged = false;
				languageOptions.map((languageOption) => {
					if (
						oriExercise?.[languageOption.code] !==
						exercise[languageOption.code]
					)
						bChanged = true;
				});

				if (
					oriExercise?.translation !== exercise.translation ||
					oriExercise?.mode !== exercise.mode ||
					oriExercise?.type !== exercise.type ||
					oriExercise?.character !== exercise.character ||
					oriExercise.file !== exercise.file ||
					oriExercise.file_1 !== exercise.file_1
				)
					bChanged = true;

				if (!bChanged) {
					return;
				}

				if (
					(oriExercise?.type === exercise.type &&
						oriExercise?.character === exercise.character &&
						oriExercise?.[language.code] ===
							exercise[language.code]) ||
					exercise.type < 2 ||
					oriExercise.file === 'error' ||
					oriExercise.file_1 === 'error'
				) {
					if (
						oriExercise.file === 'error' ||
						oriExercise.file_1 === 'error'
					) {
					} else {
						exercise.provider = 0;
						exercise.provider_1 = 0;
					}
					tmpExercises.push(exercise);
					return;
				}

				exercise.provider = 0;
				exercise.provider_1 = 0;

				if (exercise.type === 2) {
					const character_m = characters.find(
						(character) => character.name === 'user1'
					);
					if (character_m === undefined) {
						showToastr(
							'error',
							'Sync Error',
							'No Character for User(Male).'
						);

						return;
					}

					const voiceId_m = character_m['voice_' + language.code];
					const voice_m = voices.find(
						(voice) => voice.id === voiceId_m
					);
					if (voice_m === undefined) {
						showToastr(
							'error',
							'Sync Error',
							'No Voice for Character(Male).'
						);

						return;
					}

					const audio_m = await textToSpeech(exercise, voice_m);
					if (audio_m === 'error') {
						failed = true;
					}

					exercise.provider = voice_m.provider;
					exercise.file = audio_m;

					const character_f = characters.find(
						(character) => character.name === 'user2'
					);
					if (character_f === undefined) {
						showToastr(
							'error',
							'Sync Error',
							'No Character for User(Female).'
						);
						return;
					}

					const voiceId_f = character_f['voice_' + language.code];
					const voice_f = voices.find(
						(voice) => voice.id === voiceId_f
					);
					if (voice_f === undefined) {
						showToastr(
							'error',
							'Sync Error',
							'No Voice for Character(Female).'
						);

						return;
					}

					const audio_f = await textToSpeech(exercise, voice_f);
					if (audio_f === 'error') {
						failed = true;
					}

					exercise.provider_1 = voice_f.provider;
					exercise.file_1 = audio_f;
				} else if (exercise.type === 3) {
					const character = characters.find(
						(character) => character.id === exercise.character
					);

					if (character === undefined) {
						showToastr(
							'error',
							'Sync Error',
							'No Character for ' + exercise.character
						);

						return;
					}

					if (!character.name.includes('acapela')) {
						const voiceId = character['voice_' + language.code];
						const voice = voices.find(
							(voice) => voice.id === voiceId
						);
						if (voice === undefined) {
							showToastr(
								'error',
								'Sync Error',
								'No Voice for Character(' +
									character.name +
									').'
							);

							return;
						}

						const audio = await textToSpeech(exercise, voice);
						if (audio === 'error') {
							failed = true;
						}

						exercise.provider = voice.provider;
						exercise.file = audio;
					} else {
						exercise.provider = 3;
					}
				}

				if (failed)
					showToastr(
						'error',
						'Sync Error',
						`Audio error for '${exercise[language.code]}'!`
					);

				tmpExercises.push(exercise);
			};

			await updatedExercises.reduce(async (previous, inExercise) => {
				await previous;
				return getAudio(inExercise);
			}, Promise.resolve(null));

			ApiService.updateActivity(session, id, activity, tmpExercises, sync)
				.then((response) => response.json())
				.then((data) => {
					setWaiting(false);

					if (!failed)
						history.push('/admin/journeys/activities', {
							created: id ? false : true,
						});
					return;
				})
				.catch((err) => {
					console.error(err);

					setWaiting(false);
				});
		}

		return;
	};

	const handleLanguageChange = (item) => {
		setLanguage(item);

		setSheetOptions(
			sheets.filter((sheet) => sheet.value.includes(item.code))
		);

		setSheet(sheets.filter((sheet) => sheet.value.includes(item.code))[0]);

		let levelOptions = [];
		let chapterOptions = [];

		levels
			.filter((level) => level.language_id === item.value)
			.map((level) => {
				if (
					levelOptions.find(
						(levelOption) => levelOption.value === level.id
					) === undefined
				) {
					levelOptions.push({
						value: level.id,
						label: level.level,
					});
				}
			});

		setLevelOptions(levelOptions);

		setLevel(levelOptions[0]);

		chapters
			.filter((chapter) => chapter.level_id === levelOptions[0].value)
			.map((chapter) => {
				if (
					chapterOptions.find(
						(chapterOption) => chapterOption.value === chapter.id
					) === undefined
				) {
					chapterOptions.push({
						value: chapter.id,
						label: chapter[item.code],
					});
				}
			});

		setChapterOptions(chapterOptions);

		setChapter(chapterOptions[0]);
	};

	const handleLevelChange = (item) => {
		setLevel(item);

		let chapterOptions = [];

		chapters
			.filter((chapter) => chapter.level_id === item.value)
			.map((chapter) => {
				if (
					chapterOptions.find(
						(chapterOption) => chapterOption.value === chapter.id
					) === undefined
				) {
					chapterOptions.push({
						value: chapter.id,
						label: chapter[language.code],
					});
				}
			});

		setChapterOptions(chapterOptions);

		setChapter(chapterOptions[0]);
	};

	const handleChapterChange = (item) => {
		setChapter(item);
	};

	const handleBrowse = (type) => {
		if (type === 'image') {
			fileDialog({ accept: 'image/*', multiple: true })
				.then(async (files) => {
					let tmpImages = images.slice();
					for (let i = 0; i < files.length; i++) {
						if (
							tmpImages.find(
								(tmpImage) =>
									tmpImage.file.name == files[i].name
							) === undefined
						) {
							const imageData = await getImageData(files[i]);
							tmpImages.push({
								img: imageData,
								file: files[i],
							});
						}
					}

					setImages(tmpImages);
				})
				.catch((error) => {
					console.log(error);
				});
		} else {
			fileDialog({ accept: 'audio/*', multiple: true })
				.then((files) => {
					let tmpAudios = audios.slice();
					for (let i = 0; i < files.length; i++) {
						if (
							tmpAudios.find(
								(tmpAudio) => tmpAudio.name == files[i].name
							) === undefined
						)
							tmpAudios.push(files[i]);
					}

					setAudios(tmpAudios);
				})
				.catch((error) => {
					console.log(error);
				});
		}
	};

	const handleAddImage = () => {
		let tmpUpdatedExercises = updatedExercises.slice();

		fileDialog({ accept: 'image/*' })
			.then((file) => {
				let reader = new FileReader();

				reader.onload = (e) => {
					tmpUpdatedExercises.push({
						id: 0,
						activity_id: id,
						type: 0,
						character: 0,
						mode: 0,
						translation: 0,
						image: [reader.result],
						en: '',
						es: '',
						fr: '',
						de: '',
						file: file[0],
					});
					setUpdatedExercises(tmpUpdatedExercises);
				};

				reader.readAsDataURL(file[0]);

				window.scrollTo(0, document.body.scrollHeight);
			})
			.catch((error) => {
				console.log(error);
			});
	};

	const handleEditImage = (type, index, file) => {
		if (index === 0) {
			let tmpActivity = JSON.parse(JSON.stringify(activity));
			tmpActivity.image = file;

			setActivity(tmpActivity);

			return;
		}

		if (type === 'google') {
			let tmpImages = images.slice();

			tmpImages[index].file = file;

			setImages(tmpImages);
		} else {
			let tmpUpdatedExercises = updatedExercises.slice();
			tmpUpdatedExercises[index].file = file;

			setUpdatedExercises(tmpUpdatedExercises);
		}
	};

	const handleDeleteImage = (type, index) => {
		if (index === 0) return;

		if (type === 'google') {
			let tmpImages = images.slice();
			tmpImages.splice(index, 1);

			setImages(tmpImages);
		} else {
			let tmpUpdatedExercises = updatedExercises.slice();
			tmpUpdatedExercises.splice(index, 1);

			setUpdatedExercises(tmpUpdatedExercises);
		}
	};

	const handleAddText = () => {
		let tmpUpdatedExercises = updatedExercises.slice();
		tmpUpdatedExercises.push({
			id: 0,
			activity_id: id,
			type: 1,
			character: 0,
			mode: 0,
			translation: 0,
			en: '',
			es: '',
			fr: '',
			de: '',
			audio: '',
			file: '',
		});
		setUpdatedExercises(tmpUpdatedExercises);

		window.scrollTo(0, document.body.scrollHeight);
	};

	const handleEditText = (type, index, val) => {
		let tmpUpdatedExercises = updatedExercises.slice();
		tmpUpdatedExercises[index][type] = val;

		setUpdatedExercises(tmpUpdatedExercises);
	};

	const handleAddCharacter = () => {
		let tmpUpdatedExercises = updatedExercises.slice();
		tmpUpdatedExercises.push({
			id: 0,
			activity_id: id,
			type: 2,
			character: 1,
			mode: 1,
			translation: 1,
			en: '',
			es: '',
			fr: '',
			de: '',
			audio: '',
		});

		setUpdatedExercises(tmpUpdatedExercises);
	};

	const handleEditType = (item, index) => {
		let tmpUpdatedExercises = JSON.parse(JSON.stringify(updatedExercises));
		if (item.value === 10000) {
			tmpUpdatedExercises[index].type = 1;
			tmpUpdatedExercises[index].character = 0;
		} else if (item.value === 10001) {
			tmpUpdatedExercises[index].type = 2;
			tmpUpdatedExercises[index].character = 0;
		} else {
			tmpUpdatedExercises[index].type = 3;
			tmpUpdatedExercises[index].character = item.value;
		}

		setUpdatedExercises(tmpUpdatedExercises);
	};

	const handleEditMode = (item, index) => {
		let tmpUpdatedExercises = updatedExercises.slice();
		tmpUpdatedExercises[index].mode = item.value;

		setUpdatedExercises(tmpUpdatedExercises);
	};

	const handleEditTranslation = (item, index) => {
		let tmpUpdatedExercises = updatedExercises.slice();
		tmpUpdatedExercises[index].translation = item.value;

		setUpdatedExercises(tmpUpdatedExercises);
	};

	const handleAudioChange = (index) => {
		fileDialog({ accept: 'audio/*' })
			.then((files) => {
				let tmpUpdatedExercises = updatedExercises.slice();
				let tmpExercise = tmpUpdatedExercises[index];
				tmpExercise.audio = URL.createObjectURL(files[0]);
				tmpExercise.file = files[0];
				tmpUpdatedExercises[index] = tmpExercise;

				setUpdatedExercises(tmpUpdatedExercises);
				audioRef.current[index].pause();
				audioRef.current[index].load();
			})
			.catch((error) => {
				console.log(error);
			});
	};

	const handleRefresh = async (exercise, index, user = 0) => {
		if (user === 0) {
			const character = characters.find(
				(character) => character.id === exercise.character
			);

			const voiceId = character['voice_' + language.code];
			const voice = voices.find((voice) => voice.id === voiceId);
			if (voice === undefined) {
				showToastr(
					'error',
					'Sync Error',
					'No Voice for Character(' + character.name + ').'
				);
				setWaiting(false);

				return;
			}

			const audio = await textToSpeech(exercise, voice);
			if (audio === 'error') {
				showToastr(
					'error',
					'Sync Error',
					`Audio error for '${exercise[language.code]}'!`
				);

				return;
			} else {
				showToastr('success', 'Generation Success', 'Audio generated.');
			}

			let tmpUpdatedExercises = updatedExercises.slice();
			let tmpExercise = tmpUpdatedExercises[index];
			tmpExercise.file = audio;
			tmpExercise.provider = voice.provider;
			tmpExercise.provider_1 = 0;
			tmpUpdatedExercises[index] = tmpExercise;

			setUpdatedExercises(tmpUpdatedExercises);
		} else {
			const character = characters.find((character) =>
				(character.name === user) === 1 ? 'user1' : 'user2'
			);
			if (character === undefined) {
				showToastr('error', 'Sync Error', 'No Character for User.');

				return;
			}

			const voiceId = character['voice_' + language.code];
			const voice = voices.find((voice) => voice.id === voiceId);
			if (voice === undefined) {
				showToastr('error', 'Sync Error', 'No Voice for Character.');

				return;
			}

			const audio = await textToSpeech(exercise, voice);
			if (audio === 'error') {
				showToastr(
					'error',
					'Sync Error',
					`Audio error for '${exercise[language.code]}'!`
				);

				return;
			} else {
				showToastr('success', 'Generation Success', 'Audio generated.');
			}

			let tmpUpdatedExercises = updatedExercises.slice();
			let tmpExercise = tmpUpdatedExercises[index];
			tmpExercise[user === 1 ? 'file' : 'file_1'] = audio;
			tmpExercise[user === 1 ? 'provider' : 'provider_1'] =
				voice.provider;
			tmpUpdatedExercises[index] = tmpExercise;

			setUpdatedExercises(tmpUpdatedExercises);
		}
	};

	if (!characters || !language) {
		return (
			<Container
				fluid
				className='vh-50 d-flex justify-content-center align-items-center'>
				<Spinner color='primary' size='lg' className='mr-2' />
			</Container>
		);
	}

	let typeOptions = [
		{
			value: 10000,
			label: 'Text',
			voice: 0,
		},
		{
			value: 10001,
			label: 'user',
			voice: 0,
		},
		{
			value: 10002,
			label: 'options',
			voice: 0,
		},
	];

	characters
		.filter(
			(character) =>
				character.name !== 'user1' && character.name !== 'user2'
		)
		.map((character) => {
			typeOptions.push({
				value: character.id,
				label: character.name,
				voice: character['voice_' + language.code],
			});
		});

	if (!activity) {
		return (
			<Container
				fluid
				className='vh-50 d-flex justify-content-center align-items-center'>
				<Spinner color='primary' size='lg' className='mr-2' />
			</Container>
		);
	}

	if (id && !activity) {
		return (
			<Container
				fluid
				className='vh-50 d-flex justify-content-center align-items-center'>
				<h1>No Activity Found!</h1>
			</Container>
		);
	}

	return (
		<Container fluid className='p-0'>
			<Col>
				<h1 className='h3 mb-3'>Activity</h1>
				<h5 className='mt-3'>Languages</h5>
				<Select
					className='react-select-container'
					classNamePrefix='react-select'
					options={languageOptions}
					isSearchable={false}
					onChange={(item) => handleLanguageChange(item)}
					value={language}
				/>
				<h5 className='mt-3'>Levels</h5>
				<Select
					className='react-select-container'
					classNamePrefix='react-select'
					options={levelOptions}
					isSearchable={false}
					onChange={(item) => handleLevelChange(item)}
					value={level}
				/>
				<h5 className='mt-3'>Chapters</h5>
				<Select
					className='react-select-container'
					classNamePrefix='react-select'
					options={chapterOptions}
					isSearchable={false}
					onChange={(item) => handleChapterChange(item)}
					value={chapter}
				/>
				<h5 className='mt-3'>Sync Type</h5>
				<div className='d-flex'>
					<FormGroup check className='mr-4'>
						<Label check>
							<Input
								type='radio'
								name='sync'
								onChange={() => setSync(true)}
								checked={sync}
							/>{' '}
							Google Sheet
						</Label>
					</FormGroup>
					<FormGroup check>
						<Label check>
							<Input
								type='radio'
								name='sync'
								onChange={() => setSync(false)}
							/>{' '}
							Custom
						</Label>
					</FormGroup>
				</div>
				{sync ? (
					<div>
						<h5 className='mt-3'>Google Sheet Url</h5>
						<Input
							name='Sheet-Url'
							value={activity.google_sheet_url}
							onChange={(val) =>
								setActivity({
									...activity,
									google_sheet_url: val.target.value,
								})
							}
						/>
						<h5 className='mt-3'>Sheet Name</h5>
						<Select
							className='react-select-container'
							classNamePrefix='react-select'
							options={sheetOptions}
							isSearchable={false}
							value={sheet}
							onChange={(item) => setSheet(item)}
						/>
						<h5 className='mt-3'>Image</h5>
						<Button
							color='primary'
							onClick={() => handleBrowse('image')}>
							Browse
						</Button>
						<div className='d-flex flex-wrap mt-3'>
							{images.map((image, index) => (
								<Fragment key={index}>
									<Image
										img={image.img}
										name={image.file.name}
										onChange={(file) =>
											handleEditImage(
												'google',
												index,
												file
											)
										}
										onDelete={() =>
											handleDeleteImage('google', index)
										}
									/>
								</Fragment>
							))}
						</div>
						<h5 className='mt-3'>Audios</h5>
						<Button
							color='primary'
							onClick={() => handleBrowse('audio')}>
							Browse
						</Button>
						<div className='d-flex flex-wrap mt-3'>
							{audios.map((audio, index) => (
								<Fragment key={index}>
									<Audio
										audio={URL.createObjectURL(audio)}
										name={audio.name}
									/>
								</Fragment>
							))}
						</div>
					</div>
				) : (
					<div>
						<h5 className='mt-3'>Title</h5>
						<div className='mt-3 d-flex justify-content-between'>
							<div style={{ width: '24%' }}>
								<p style={{ fontSize: 12, marginBottom: 5 }}>
									English
								</p>
								<Input
									name='Title-en'
									onChange={(val) => {
										setActivity({
											...activity,
											title_en: val.target.value,
										});
									}}
									value={activity.title_en}
								/>
							</div>
							<div style={{ width: '24%' }}>
								<p style={{ fontSize: 12, marginBottom: 5 }}>
									Spanish
								</p>
								<Input
									name='Title-es'
									onChange={(val) => {
										setActivity({
											...activity,
											title_es: val.target.value,
										});
									}}
									value={activity.title_es}
								/>
							</div>
							<div style={{ width: '24%' }}>
								<p style={{ fontSize: 12, marginBottom: 5 }}>
									French
								</p>
								<Input
									name='Title-fr'
									onChange={(val) => {
										setActivity({
											...activity,
											title_fr: val.target.value,
										});
									}}
									value={activity.title_fr}
								/>
							</div>
							<div style={{ width: '24%' }}>
								<p style={{ fontSize: 12, marginBottom: 5 }}>
									German
								</p>
								<Input
									name='Title-de'
									onChange={(val) => {
										setActivity({
											...activity,
											title_de: val.target.value,
										});
									}}
									value={activity.title_de}
								/>
							</div>
						</div>
						<h5 className='mt-3'>Link</h5>
						<Input
							name='Link'
							onChange={(val) => {
								setActivity({
									...activity,
									link: val.target.value,
								});
							}}
							style={{ width: 200 }}
							value={activity.link}
						/>
						<UncontrolledButtonDropdown className='mr-1 mt-3'>
							<DropdownToggle
								color='primary'
								className='btn-pill mr-1 mb-3'
								outline
								caret
								size='md'>
								<FontAwesomeIcon
									icon={faPlusCircle}
									color='primary'
									className='mr-2'
								/>
								Add Item
							</DropdownToggle>
							<DropdownMenu>
								<DropdownItem onClick={handleAddImage}>
									Image
								</DropdownItem>
								<DropdownItem onClick={handleAddText}>
									Text
								</DropdownItem>
								<DropdownItem onClick={handleAddCharacter}>
									Character
								</DropdownItem>
							</DropdownMenu>
						</UncontrolledButtonDropdown>

						<p className='mt-3'>Image</p>
						<Image
							img={config.UPLOAD_URL + activity.image}
							onChange={(file) =>
								handleEditImage('custom', 0, file)
							}
							onDelete={() => handleDeleteImage('custom', 0)}
						/>

						{updatedExercises &&
							updatedExercises.map((exercise, index) =>
								exercise.type === 0 ? (
									<Fragment key={index}>
										<p className='mt-3'>Image</p>
										<Image
											img={
												exercise.image ??
												config.UPLOAD_URL +
													exercise.file
											}
											onChange={(file) =>
												handleEditImage(
													'custom',
													index,
													file
												)
											}
											onDelete={() =>
												handleDeleteImage(
													'custom',
													index
												)
											}
										/>
									</Fragment>
								) : (
									<div
										className='mt-3'
										key={index}
										style={{
											border: '1px solid gray',
											borderRadius: 6,
											padding: 10,
										}}>
										<div className='d-flex'>
											<div>
												<p
													style={{
														fontSize: 12,
														marginBottom: 5,
													}}>
													Type
												</p>
												<Select
													className='react-select-container'
													classNamePrefix='react-select'
													styles={customStyles}
													options={typeOptions}
													isSearchable={true}
													defaultValue={
														exercise.id
															? exercise.type ===
															  1
																? typeOptions.find(
																		(
																			typeOption
																		) =>
																			typeOption.value ===
																			10000
																  )
																: exercise.type ===
																  2
																? typeOptions.find(
																		(
																			typeOption
																		) =>
																			typeOption.value ===
																			10001
																  )
																: exercise.type ===
																  4
																? typeOptions.find(
																		(
																			typeOption
																		) =>
																			typeOption.value ===
																			10002
																  )
																: typeOptions.find(
																		(
																			typeOption
																		) =>
																			typeOption.value ===
																			exercise.character
																  )
															: typeOptions[0]
													}
													onChange={(item) =>
														handleEditType(
															item,
															index
														)
													}
												/>
											</div>
											<div>
												<p
													style={{
														fontSize: 12,
														marginBottom: 5,
													}}>
													Mode
												</p>
												<Select
													className='react-select-container'
													classNamePrefix='react-select'
													styles={customStyles}
													options={modeOptions}
													isSearchable={false}
													defaultValue={
														exercise.id
															? modeOptions.find(
																	(
																		modeOption
																	) =>
																		modeOption.value ===
																		exercise.mode
															  )
															: modeOptions[0]
													}
													onChange={(item) =>
														handleEditMode(
															item,
															index
														)
													}
												/>
											</div>
											<div>
												<p
													style={{
														fontSize: 12,
														marginBottom: 5,
													}}>
													Translation
												</p>
												<Select
													className='react-select-container'
													classNamePrefix='react-select'
													styles={customStyles}
													options={translationOptions}
													isSearchable={false}
													defaultValue={
														exercise.id
															? translationOptions.find(
																	(
																		translationOption
																	) =>
																		translationOption.value ===
																		exercise.translation
															  )
															: translationOptions[0]
													}
													onChange={(item) =>
														handleEditTranslation(
															item,
															index
														)
													}
												/>
											</div>
											<div className='d-flex justify-content-between'>
												<div style={{ width: '24%' }}>
													<p
														style={{
															fontSize: 12,
															marginBottom: 5,
														}}>
														English
													</p>
													<Input
														name='Search-Level'
														onChange={(val) =>
															handleEditText(
																'en',
																index,
																val.target.value
															)
														}
														value={exercise.en}
													/>
												</div>
												<div style={{ width: '24%' }}>
													<p
														style={{
															fontSize: 12,
															marginBottom: 5,
														}}>
														Spanish
													</p>
													<Input
														name='Search-Level'
														onChange={(val) =>
															handleEditText(
																'es',
																index,
																val.target.value
															)
														}
														value={exercise.es}
													/>
												</div>
												<div style={{ width: '24%' }}>
													<p
														style={{
															fontSize: 12,
															marginBottom: 5,
														}}>
														French
													</p>
													<Input
														name='Search-Level'
														onChange={(val) =>
															handleEditText(
																'fr',
																index,
																val.target.value
															)
														}
														value={exercise.fr}
													/>
												</div>
												<div style={{ width: '24%' }}>
													<p
														style={{
															fontSize: 12,
															marginBottom: 5,
														}}>
														German
													</p>
													<Input
														name='Search-Level'
														onChange={(val) =>
															handleEditText(
																'de',
																index,
																val.target.value
															)
														}
														value={exercise.de}
													/>
												</div>
											</div>
										</div>
										{exercise.type === 2 ? (
											<div className='row mt-2'>
												<div className='col-sm-6 mt-2'>
													<p>Male</p>
													<div className='d-flex align-items-center mt-2'>
														<audio
															controls
															controlsList='nodownload'>
															<source
																src={
																	exercise.file ===
																	'error'
																		? ''
																		: exercise.file
																}
																type='audio/mpeg'
															/>
															Your browser does
															not support the
															audio element.
														</audio>
														{exercise.file ===
														'error' ? (
															<Button
																className='p-0 ml-2'
																color='none'
																onClick={() =>
																	handleRefresh(
																		exercise,
																		index,
																		1
																	)
																}>
																<RefreshCcw
																	className='align-middle text-danger'
																	size={18}
																/>
															</Button>
														) : null}
													</div>
												</div>
												<div className='col-sm-6 mt-2'>
													<p>Female</p>
													<div className='d-flex align-items-center mt-2'>
														<audio
															controls
															controlsList='nodownload'>
															<source
																src={
																	exercise.file_1 ===
																	'error'
																		? ''
																		: exercise.file_1
																}
																type='audio/mpeg'
															/>
															Your browser does
															not support the
															audio element.
														</audio>
														{exercise.file_1 ===
														'error' ? (
															<Button
																className='p-0 ml-2'
																color='none'
																onClick={() =>
																	handleRefresh(
																		exercise,
																		index,
																		2
																	)
																}>
																<RefreshCcw
																	className='align-middle text-danger'
																	size={18}
																/>
															</Button>
														) : null}
													</div>
												</div>
											</div>
										) : exercise.type === 3 ? (
											<div className='d-flex align-items-center mt-2'>
												{exercise.character ? (
													<Image
														img={
															config.UPLOAD_URL +
															(characters.find(
																(character) =>
																	character.id ===
																	exercise.character
															).name === 'male1'
																? activity.image_male1
																: characters.find(
																		(
																			character
																		) =>
																			character.id ===
																			exercise.character
																  ).name ===
																  'male2'
																? activity.image_male2
																: characters.find(
																		(
																			character
																		) =>
																			character.id ===
																			exercise.character
																  ).name ===
																  'female1'
																? activity.image_female1
																: characters.find(
																		(
																			character
																		) =>
																			character.id ===
																			exercise.character
																  ).name ===
																  'female2'
																? activity.image_female2
																: characters.find(
																		(
																			character
																		) =>
																			character.id ===
																			exercise.character
																  ).name ===
																  'acapelam'
																? activity.image_acapelam
																: characters.find(
																		(
																			character
																		) =>
																			character.id ===
																			exercise.character
																  ).name ===
																  'acapelaf'
																? activity.image_acapelaf
																: characters.find(
																		(
																			character
																		) =>
																			character.id ===
																			exercise.character
																  ).image)
														}
														readOnly
													/>
												) : null}
												<audio
													controls
													controlsList='nodownload'
													ref={(el) =>
														(audioRef.current[
															index
														] = el)
													}>
													<source
														src={
															exercise.audio ??
															(exercise.file ===
															'error'
																? ''
																: exercise.file)
														}
														type='audio/mpeg'
													/>
													Your browser does not
													support the audio element.
												</audio>
												{exercise.file === 'error' ? (
													<Button
														className='p-0 ml-2'
														color='none'
														onClick={() =>
															handleRefresh(
																exercise,
																index
															)
														}>
														<RefreshCcw
															className='align-middle text-danger'
															size={18}
														/>
													</Button>
												) : null}
												{characters
													.find(
														(character) =>
															character.id ===
															exercise.character
													)
													.name.substring(0, 7) ===
												'acapela' ? (
													<Button
														className='p-0 ml-2'
														color='none'
														onClick={() =>
															handleAudioChange(
																index
															)
														}>
														<Edit
															className='align-middle'
															size={18}
														/>
													</Button>
												) : null}
											</div>
										) : null}
									</div>
								)
							)}
					</div>
				)}
				<Row className='mt-7 justify-content-center'>
					<Button
						color='secondary'
						className='mr-3'
						onClick={() =>
							history.push('/admin/journeys/activities')
						}>
						Cancel
					</Button>
					<Button color='primary' onClick={() => handleSave()}>
						{waiting ? (
							<Spinner
								color='secondary'
								size='sm'
								className='mr-2'
							/>
						) : (
							`Save`
						)}
					</Button>
				</Row>
			</Col>
		</Container>
	);
};

export default ActivityEdit;
