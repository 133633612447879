import React, { useState, useEffect } from "react";
import {
  Container,
  Spinner,
} from "reactstrap";

import { ApiService } from "../../services/apiService";
import { showToastr } from '../../services/themeService';


const ConfirmEmail = (props) => {

  const { token } = props.match.params;
  console.log(token);

  const [confirmed, setConfirmed] = useState(false);

  useEffect(() => {
    ApiService.confirmEmail(token)
      .then(response => response.json())
      .then(data => {
        if (data && data.type === "error") {
          showToastr('error', 'Invalid Token', data.message);

          return;
        }

        if (data.type === 'success') {
          setConfirmed(true);
        }
      })
      .catch((err) => {
        console.error(err);
      });
  }, []);


  if (!confirmed) {
    return (
      <Container fluid className="vh-50 d-flex justify-content-center align-items-center">
        <Spinner color="primary" size="lg" className="mr-2" />
      </Container>
    );
  }

  return (
    <React.Fragment>
      <div className="text-center mt-4">
        <h1 className="h2" style={{ color: 'greenyellow' }}>Congratlation!</h1>
      </div>


      <div className="text-center" style={{ marginTop: 100 }}>
        <h1>
          Your Email confirmed
        </h1>
        <br></br>
        <h1>
          You are now a member of <span style={{ color: 'orange', marginLeft: 10 }}>Language Journeys.</span>
        </h1>
        <h4 style={{ marginTop: 50 }}>
          Please go back to the app and log in.
        </h4>
      </div>
    </React.Fragment>
  );
}


export default ConfirmEmail;
