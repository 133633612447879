import React, { useState, Fragment } from 'react';
import {
  Button,
  Card,
  CardImg,
  CardBody,
  CardText,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from "reactstrap";
import { Edit, Trash2, } from "react-feather";
import fileDialog from 'file-dialog';


const Image = (props) => {

  const { img, name, onChange, onDelete, readOnly } = props;
  const [image, setImage] = useState(img);
  const [fileName, setFileName] = useState(name);
  const [open, setOpen] = useState(false);

  const handleChange = () => {
    fileDialog({ accept: 'image/*' })
      .then(file => {
        let reader = new FileReader();

        reader.onload = (e) => {
          console.log('safd');
          setImage([reader.result]);
        }

        reader.readAsDataURL(file[0]);

        setFileName(file[0].name);
        onChange(file[0]);
      });
  }

  const handleDelete = () => {
    setOpen(true);
  }

  const handleConfirm = () => {
    setOpen(false);

    onDelete();
  }


  return (
    <Fragment>
      <Card style={{ width: readOnly ? 100 : 150, margin: 5, }}>
        <CardImg top src={image} alt="Card image cap" style={{ height: readOnly ? 70 : 100, }} />
        {
          !readOnly ? (
            <CardBody>
              <CardText className="text-center">
                {fileName}
              </CardText>

              <div className="d-flex align-items-center" style={{ float: 'right', }}>
                <Button className="p-0 mr-1" color="none" onClick={handleChange}>
                  <Edit className="align-middle" size={18} />
                </Button>
                <Button className="p-0" color="none" onClick={handleDelete}>
                  <Trash2 className="align-middle text-danger" size={18} />
                </Button>
              </div>

            </CardBody>
          ) : null
        }
      </Card>

      <Modal
        isOpen={open}
        toggle={() => setOpen(!open)}
        centered
      >
        <ModalHeader toggle={() => setOpen(!open)}>
          Delete image
        </ModalHeader>
        <ModalBody className="text-center m-3">
          <p className="mb-0">
            Are you sure want to delete this image?
          </p>
        </ModalBody>
        <ModalFooter>
          <Button color="secondary" onClick={() => setOpen(false)}>
            Cancel
          </Button>{" "}
          <Button
            color="danger"
            onClick={handleConfirm}
          >
            Delete
          </Button>
        </ModalFooter>
      </Modal>
    </Fragment>
  );
}

export default Image;