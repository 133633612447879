import React, { useState, useEffect } from 'react';
import Select from 'react-select';
import { Button, Row } from 'reactstrap';
import { useSelector } from 'react-redux';
import { ApiService } from '../../services/apiService';

const Ads = () => {
	const options = [
		{
			value: 1,
			label: 'Every 1 activity',
		},
		{
			value: 2,
			label: 'Every 2nd activity',
		},
		{
			value: 3,
			label: 'Every 3rd activity',
		},
		{
			value: 4,
			label: 'Every 4th activity',
		},
		{
			value: 5,
			label: 'Every 5th activity',
		},
	];

	const [frequency, setFrequency, setSetFrequency] = useState();
	const [frequencyOptions, setFrequencyOptions] = useState(options);

	useEffect(() => {
		ApiService.getAdFrequency()
			.then((response) => response.json())
			.then((data) => {
				const non_selected_options = frequencyOptions.filter(
					(option) => option.value !== data.frequency
				);

				const selected_option = frequencyOptions.filter(
					(option) => option.value === data.frequency
				);

				setFrequency(() => selected_option);
				setFrequencyOptions(() => non_selected_options);
			});
	}, []);

	const handleSave = () => {
		ApiService.updateAdFrequency(frequency)
			.then((response) => {
				alert('Ad Frequency Updated.');
			})
			.catch((err) => {
				console.error(err);
			});
	};

	return (
		<>
			<h5>In-stream Ads will be inserted after:</h5>
			<Select
				className='react-select-container'
				classNamePrefix='react-select'
				options={options}
				isSearchable={false}
				onChange={(item) => setFrequency(item)}
				value={frequency}
			/>

			<Row className='mt-2 ml-1'>
				<Button color='primary' onClick={() => handleSave()}>
					Save
				</Button>
			</Row>
		</>
	);
};

export default Ads;
