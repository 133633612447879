import React, { useState } from 'react';

import {
  Button,
} from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUpload } from "@fortawesome/free-solid-svg-icons";
import fileDialog from 'file-dialog';

import { config } from '../config';


const ImageUploader = (props) => {

  const { onChange, img, round } = props;
  const [image, setImage] = useState(img ? config.UPLOAD_URL + img : '');

  const handleUpload = () => {
    fileDialog({ accept: 'image/*' })
      .then(file => {
        let reader = new FileReader();

        reader.onload = (e) => {
          console.log('safd');
          setImage([reader.result]);
        }

        reader.readAsDataURL(file[0]);

        onChange(file[0]);
      });
  }


  return (
    <div style={{ width: 128, textAlign: 'center' }}>
      {
        round ?
          <div
            className="rounded-circle img-responsive mt-2"
            style={{ width: 128, height: 128, backgroundImage: `url(${image})`, backgroundSize: '100% 100%', backgroundColor: 'gray' }}
          />
          :
          <div
            className="img-responsive mt-2"
            style={{ width: 128, height: 96, backgroundImage: `url(${image})`, backgroundSize: '100% 100%', backgroundColor: 'gray' }}
          />
      }

      <div className="mt-2">
        <Button color="primary" onClick={() => handleUpload()}>
          <FontAwesomeIcon icon={faUpload} /> Upload
        </Button>
      </div>
    </div>
  );
}

export default ImageUploader;