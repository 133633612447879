import * as types from "../constants";

const initialState = {
  activities_page: 1,
};

export default function reducer(state = initialState, actions) {
  switch (actions.type) {
    case types.ACTIVITIES_PAGE:
      return {
        ...state,
        activities_page: actions.page,
      };

    default:
      return state;
  }
}
