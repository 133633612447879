import React, { useEffect, useState } from "react";
import {
  Pagination,
  PaginationItem,
  PaginationLink,
} from "reactstrap";

const CustomPagination = (props) => {
  const { pageCount, page, onChangePage } = props;
  const [center, setCenter] = useState(1);

  useEffect(() => {
    setCenter(page);
  }, [page]);

  const handlePrev = () => {
    if (page == 1)
      return;

    onChangePage(page - 1);
  }

  const handleNext = () => {
    if (page == pageCount)
      return;

    onChangePage(page + 1);
  }

  const handleFirst = () => {
    onChangePage(1);
  }

  const handleLast = () => {
    onChangePage(pageCount);
  }

  const handlePage = (page) => {
    onChangePage(page);
  }


  return (
    <Pagination aria-label="Page navigation" size="lg">
      <PaginationItem>
        <PaginationLink first onClick={handleFirst} />
      </PaginationItem>
      <PaginationItem>
        <PaginationLink previous onClick={handlePrev} />
      </PaginationItem>
      {
        pageCount > 0 ?
          Array.from(Array(pageCount).keys()).filter(i => {
            return i >= Math.min(Math.max(center, 3), pageCount - 3) - 4 && i <= Math.min(Math.max(center, 3), pageCount - 3) + 2
          }).map((i, index) =>
            <PaginationItem active={i + 1 == page ? true : false} key={index}>
              <PaginationLink onClick={() => handlePage(i + 1)}>{i + 1}</PaginationLink>
            </PaginationItem>
          ) : null
      }
      <PaginationItem>
        <PaginationLink next onClick={handleNext} />
      </PaginationItem>
      <PaginationItem>
        <PaginationLink last onClick={handleLast} />
      </PaginationItem>
    </Pagination>
  );
}

export default CustomPagination;
