import React from "react";
import { Provider } from "react-redux";
import ReduxToastr from "react-redux-toastr";
import { PersistGate } from 'redux-persist/integration/react'
import { persistStore } from 'redux-persist';

import store from "./redux/store/index";
import Routes from "./routes/Routes";

const persistor = persistStore(store);

const App = () => (
  <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
      <Routes />
      <ReduxToastr
        timeOut={5000}
        newestOnTop={true}
        position="top-right"
        transitionIn="fadeIn"
        transitionOut="fadeOut"
        progressBar
        closeOnToastrClick
      />
    </PersistGate>
  </Provider>
);

export default App;
