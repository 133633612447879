var AWS = require('aws-sdk');

AWS.config.region = 'eu-north-1'; // Region
AWS.config.credentials = new AWS.CognitoIdentityCredentials({
	IdentityPoolId: 'eu-north-1:7b8927ac-4c8b-465d-857d-70d11d40a761',
});

const sdk = require('microsoft-cognitiveservices-speech-sdk');

export const config = {
	API_URL: 'https://app.languagejourneys.com:8443/api/',
	UPLOAD_URL: 'https://app.languagejourneys.com:8443/uploads/',
	// API_URL: 'http://192.168.109.103:8000/api/',
	// UPLOAD_URL: 'http://192.168.109.103:8000/uploads/',
	AWS: AWS,
	SDK: sdk,
};
