import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Button, Col, Container, Row, Input, Spinner } from 'reactstrap';
import Select from 'react-select';

import { ApiService } from '../../../services/apiService';
import ColorPicker from '../../../components/ColorPicker';
import { useHistory } from 'react-router-dom';

const statusOptions = [
	{ value: 1, label: 'Draft' },
	{ value: 2, label: 'Published' },
];

const LevelEdit = (props) => {
	const history = useHistory();
	const { session } = useSelector((state) => state.session);
	const page = props.location.state;

	const { id } = props.match.params;

	const [level, setLevel] = useState({
		level: '',
		color: '#ffffff',
		status: 2,
	});
	const [languages, setLanguages] = useState([]);
	const [open, setOpen] = useState(false);
	const [lang, setLang] = useState(null);

	useEffect(() => {
		ApiService.getLevel(session, id)
			.then((response) => response.json())
			.then((data) => {
				if (data && data.message === 'Unauthenticated.') {
					history.push('/admin');

					return;
				}

				const { level, langs } = data;
				if (id && !level) {
					setLevel(null);
					return;
				}

				let languages = new Array();

				langs.map((lang) => {
					languages.push({
						value: lang.id,
						label: lang.code,
					});
				});

				setLanguages(languages);

				if (!level) return;

				setLevel({
					level: level.level,
					color: level.color,
					status: level.status,
				});

				setLang(languages[level.language_id - 1]);
			})
			.catch((err) => {
				console.error(err);
			});
	}, []);

	const toggle = () => {
		setOpen(!open);
	};

	const handleSave = () => {
		ApiService.updateLevel(session, id, level, lang.value)
			.then((response) => {
				history.push('/admin/journeys/levels', page ? page : '');
			})
			.catch((err) => {
				console.error(err);
			});

		toggle();
	};

	if (id && !languages.length && level) {
		return (
			<Container
				fluid
				className='vh-50 d-flex justify-content-center align-items-center'>
				<Spinner color='primary' size='lg' className='mr-2' />
			</Container>
		);
	}

	if (id && !level) {
		return (
			<Container
				fluid
				className='vh-50 d-flex justify-content-center align-items-center'>
				<h1>No Level Found!</h1>
			</Container>
		);
	}

	return (
		<Container fluid className='p-0'>
			<h1 className='h3 mb-3'>Level</h1>

			<Row>
				<Col>
					<h5>Languages</h5>
					<Select
						className='react-select-container'
						classNamePrefix='react-select'
						options={languages}
						isSearchable={false}
						onChange={(item) => setLang(item)}
						value={lang}
					/>
					<h5 className='mt-3'>Level</h5>
					<Input
						name='Level'
						onChange={(val) => {
							setLevel({ ...level, level: val.target.value });
						}}
						value={level.level}
					/>
					<h5 className='mt-3'>Choose Color</h5>
					<ColorPicker
						color={level.color}
						onChange={(color) =>
							setLevel({ ...level, color: color.hex })
						}
					/>
					<h5 className='mt-3'>Status</h5>
					<Select
						className='react-select-container'
						classNamePrefix='react-select'
						options={statusOptions}
						isSearchable={false}
						value={statusOptions.filter(
							(option) => option.value == level.status
						)}
						onChange={(item) =>
							setLevel({ ...level, status: item.value })
						}
					/>
					<Row className='mt-7 justify-content-center'>
						<Button
							color='secondary'
							className='mr-3'
							onClick={() =>
								history.push(
									'/admin/journeys/levels',
									page ? page : ''
								)
							}>
							Cancel
						</Button>
						<Button color='primary' onClick={() => handleSave()}>
							Save
						</Button>
					</Row>
				</Col>
			</Row>
		</Container>
	);
};

export default LevelEdit;
