import React from 'react';
import {
  Row
} from 'reactstrap';


const TableTextCell = (props) => {

  const { children } = props;

  return (
    <Row className="d-flex align-items-center" style={{ padding: '0 10px', }}>
      <div style={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis', width: '100%', display: 'inline-block', }}>
        {children}
      </div>
    </Row>
  );
}

export default TableTextCell;