import React, { useState } from "react";
import { connect } from "react-redux";
import { Link, useHistory } from "react-router-dom";

import {
  Button,
  Card,
  CardBody,
  Form,
  FormGroup,
  Label,
  Input,
  CustomInput
} from "reactstrap";

import { ApiService } from "../../services/apiService";
import { sessionLogin } from "../../redux/actions/sessionActions";
import { showToastr } from '../../services/themeService';


const Home = ({ dispatch }) => {

  const history = useHistory();

  const [email, setEmail] = useState('');
  const [pass, setPass] = useState('');
  const [remember, setRemember] = useState(false);

  const handleSignin = () => {
    ApiService.login(email, pass)
      .then(response => response.json())
      .then(data => {
        console.log(data);
        if (data.type === 'error') {
          console.log(data.message);
          showToastr("error", "Login Error", data.message);

          return;
        }

        showToastr("success", 'Login', 'Login Success!');
        dispatch(sessionLogin(data.user, data.token_type + ' ' + data.access_token));
        history.push('/admin/dashboard');
      })
      .catch((err) => {
        console.error(err);
      });
  }


  return (
    <React.Fragment>
      <div className="text-center mt-4">
        <h2>Welcome</h2>
        <p className="lead">Sign in to your account to continue</p>
      </div>

      <Card>
        <CardBody>
          <div className="m-sm-4">
            <Form>
              <FormGroup>
                <Label>Email</Label>
                <Input
                  bsSize="lg"
                  type="email"
                  name="email"
                  placeholder="Enter your email"
                  value={email}
                  onChange={(val) => setEmail(val.target.value)}
                />
              </FormGroup>
              <FormGroup>
                <Label>Password</Label>
                <Input
                  bsSize="lg"
                  type="password"
                  name="password"
                  placeholder="Enter your password"
                  className="mb-2"
                  value={pass}
                  onChange={(val) => setPass(val.target.value)}
                />
                <small>
                  <Link to="/admin/auth/reset-password">Forgot password?</Link>
                </small>
              </FormGroup>
              <div>
                <CustomInput
                  type="checkbox"
                  id="rememberMe"
                  label="Remember me next time"
                  value={remember}
                  onChange={() => setRemember(!remember)}
                />
              </div>
              <div className="text-center mt-3">
                <Button color="primary" size="lg" onClick={handleSignin}>
                  Sign in
                </Button>
              </div>
            </Form>
          </div>
        </CardBody>
      </Card>
    </React.Fragment>
  );
}

export default connect()(Home);
