import { createStore } from "redux";
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

import rootReducer from "../reducers/index";


const persistConfig = {
  key: 'root',
  storage,
  blacklist: ['app',],
}

const persistedReducer = persistReducer(persistConfig, rootReducer);

const store = createStore(persistedReducer);

export default store;
