import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import {
	Button,
	Card,
	CardBody,
	Col,
	Container,
	Row,
	Pagination,
	PaginationItem,
	PaginationLink,
	Input,
	Table,
	Spinner,
	Modal,
	ModalHeader,
	ModalBody,
	ModalFooter,
} from 'reactstrap';
import Select from 'react-select';
import { useHistory } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlusCircle } from '@fortawesome/free-solid-svg-icons';
import { Edit, Trash2 } from 'react-feather';

import CustomPagination from '../../../components/CustomPagination'; //for custom pagination
import TableTextCell from '../../../components/TableTextCell';
import { ApiService } from '../../../services/apiService';
import { config } from '../../../config';

const statusOptions = [
	{ value: 0, label: 'All' },
	{ value: 1, label: 'Draft' },
	{ value: 2, label: 'Published' },
];

const Chapters = (props) => {
	const history = useHistory();
	const { session } = useSelector((state) => state.session);

	const [chapters, setChapters] = useState(null);
	const [currentChapter, setCurrentChapter] = useState({});
	const [searchValue, setSearchValue] = useState({
		lang: '',
		level: '',
		title: '',
		status: 0,
	});
	const [page, setPage] = useState(
		props.location.state ? props.location.state : 1
	);

	const [open, setOpen] = useState(false);

	useEffect(() => {
		ApiService.getChapters(session)
			.then((response) => response.json())
			.then((data) => {
				if (data && data.message === 'Unauthenticated.') {
					history.push('/admin');

					return;
				}

				console.log(data);
				setChapters(data);
			})
			.catch((err) => {
				console.error(err);
			});
	}, []);

	const toggle = () => {
		setOpen(!open);
	};

	const handleEdit = (id) => {
		history.push(`/admin/journeys/chapter/${id}`, page);
	};

	const handleDelete = (id) => {
		console.log(id);

		const curChapter = chapters.filter((chapter) => chapter.id == id);

		if (curChapter.length > 0) setCurrentChapter(curChapter[0]);

		toggle();
	};

	const handleConfirm = () => {
		console.log(currentChapter.id);
		ApiService.deleteChapter(session, currentChapter.id)
			.then((data) => {
				console.log(data);
				let tmpChapters = chapters.slice();
				const curChapter = chapters.filter(
					(chapter) => chapter.id == currentChapter.id
				);
				if (curChapter.length > 0) {
					const index = tmpChapters.indexOf(curChapter[0]);
					console.log(curChapter[0]);
					console.log(index);
					if (index > -1) {
						tmpChapters.splice(index, 1);
					}
				}
				console.log('Delete');
				console.log(tmpChapters);

				setChapters(tmpChapters);
			})
			.catch((err) => {
				console.error(err);
			});

		toggle();
	};

	const filteredChapters =
		chapters && chapters.length > 0
			? chapters.filter(
					(chapter) =>
						chapter.language.code
							.toLowerCase()
							.includes(searchValue.lang.toLowerCase()) &&
						chapter.level.level
							.toLowerCase()
							.includes(searchValue.level.toLowerCase()) &&
						chapter.en
							.toLowerCase()
							.includes(searchValue.title.toLowerCase()) &&
						(searchValue.status == 0
							? true
							: chapter.status == searchValue.status)
			  )
			: new Array();

	let pageCount = 0;
	if (filteredChapters.length > 0) {
		pageCount = parseInt(filteredChapters.length / 20) + 1;
	}

	const handlePrev = () => {
		if (page == 1) return;

		setPage(page - 1);
	};

	const handleNext = () => {
		if (page == pageCount) return;

		setPage(page + 1);
	};

	const handlePage = (index) => {
		setPage(index);
	};

	//added for custom pagination
	const handleChangePage = (index) => {
		setPage(index);
	};

	if (!chapters) {
		return (
			<Container
				fluid
				className='vh-50 d-flex justify-content-center align-items-center'>
				<Spinner color='primary' size='lg' className='mr-2' />
			</Container>
		);
	}

	return (
		<Container fluid className='p-0'>
			<h1 className='h3 mb-3'>Journey Chapters</h1>
			<Button
				color='primary'
				className='btn-pill mr-1 mb-3'
				outline
				onClick={() => history.push('/admin/journeys/chapter')}>
				<FontAwesomeIcon
					icon={faPlusCircle}
					color='primary'
					className='mr-2'
				/>
				Add New Chapter
			</Button>

			<Row>
				<Col>
					<Card>
						{/* <CardHeader>
              <CardTitle tag="h5" className="mb-0">Empty card</CardTitle>
            </CardHeader> */}
						<CardBody>
							<Table bordered style={{ tableLayout: 'fixed' }}>
								<thead>
									<tr>
										<th
											className='text-center'
											style={{ width: 60 }}>
											No
										</th>
										<th className='text-center'>
											Language
										</th>
										<th className='text-center'>Level</th>
										<th className='text-center'>Title</th>
										<th
											className='text-center'
											style={{ width: 70 }}>
											Image
										</th>
										<th className='text-center'>Status</th>
										<th
											className='text-center'
											style={{ width: 85 }}>
											Actions
										</th>
									</tr>
								</thead>
								<tbody>
									<tr>
										<td></td>
										<td>
											<Input
												name='Search-Lang'
												placeholder='Search...'
												onChange={(val) => {
													setSearchValue({
														...searchValue,
														lang: val.target.value,
													});
													setPage(1);
												}}
												value={searchValue.lang}
											/>
										</td>
										<td>
											<Input
												name='Search-Level'
												placeholder='Search...'
												onChange={(val) => {
													setSearchValue({
														...searchValue,
														level: val.target.value,
													});
													setPage(1);
												}}
												value={searchValue.level}
											/>
										</td>
										<td>
											<Input
												name='Search-Title'
												placeholder='Search...'
												onChange={(val) => {
													setSearchValue({
														...searchValue,
														title: val.target.value,
													});
													setPage(1);
												}}
												value={searchValue.title}
											/>
										</td>
										<td></td>
										<td>
											<Select
												className='react-select-container'
												classNamePrefix='react-select'
												options={statusOptions}
												isSearchable={false}
												defaultValue={statusOptions[0]}
												onChange={(item) =>
													setSearchValue({
														...searchValue,
														status: item.value,
													})
												}
											/>
										</td>
										<td></td>
									</tr>

									{filteredChapters
										.slice(
											(page - 1) * 20,
											Math.min(page * 20)
										)
										.map((chapter, index) => (
											<tr key={index}>
												<td className='text-center'>
													{chapter.id}
												</td>
												<td>
													<TableTextCell>
														{chapter.language.code}
													</TableTextCell>
												</td>
												<td>
													<TableTextCell>
														{chapter.level.level}
													</TableTextCell>
												</td>
												<td>
													<TableTextCell>
														{
															chapter[
																chapter.language
																	.code
															]
														}
													</TableTextCell>
												</td>
												<td className='text-center'>
													{chapter.image ? (
														<img
															src={
																config.UPLOAD_URL +
																chapter.image +
																'?' +
																new Date().getTime()
															}
															width='42'
															height='32'
															className='my-n1'
															alt='Avatar'
														/>
													) : null}
												</td>
												<td>
													<TableTextCell>
														{chapter.status == 2
															? 'Published'
															: 'Draft'}
													</TableTextCell>
												</td>
												<td className='table-action text-center'>
													<Row
														className='d-flex justify-content-around'
														style={{
															margin: 'auto',
															width: 60,
														}}>
														<Button
															className='p-0'
															color='none'
															onClick={() =>
																handleEdit(
																	chapter.id
																)
															}>
															<Edit
																className='align-middle'
																size={18}
															/>
														</Button>
														<Button
															className='p-0'
															color='none'
															onClick={() =>
																handleDelete(
																	chapter.id
																)
															}>
															<Trash2
																className='align-middle'
																size={18}
															/>
														</Button>
													</Row>
												</td>
											</tr>
										))}
								</tbody>
							</Table>

							{/* Code for Pagination index */}

							<Row className='justify-content-center'>
								<CustomPagination
									pageCount={pageCount}
									page={page}
									onChangePage={(page) =>
										handleChangePage(page)
									}
								/>
							</Row>

							<Modal
								isOpen={open}
								toggle={() => toggle()}
								centered>
								<ModalHeader toggle={() => toggle()}>
									Delete chapter
								</ModalHeader>
								<ModalBody className='text-center m-3'>
									<p className='mb-0'>
										Are you sure want to delete this
										chapter?
									</p>
								</ModalBody>
								<ModalFooter>
									<Button
										color='secondary'
										onClick={() => toggle()}>
										Cancel
									</Button>{' '}
									<Button
										color='danger'
										onClick={() => handleConfirm()}>
										Delete
									</Button>
								</ModalFooter>
							</Modal>
						</CardBody>
					</Card>
				</Col>
			</Row>
		</Container>
	);
};

export default Chapters;
