import * as types from "../constants";

export function sessionLogin(user, session) {
  return {
    type: types.SESSION_LOGIN,
    user: user,
    session: session,
  };
}

export function sessionLogout() {
  return {
    type: types.SESSION_LOGOUT,
  };
}
