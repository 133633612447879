import React, { useState } from "react";
import { connect, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

import {
  Button,
  Collapse,
  Navbar,
  Nav,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Form,
  Input,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from "reactstrap";

import {
  Settings,
  User,
} from "react-feather";

import { toggleSidebar } from "../redux/actions/sidebarActions";
import { sessionLogout } from "../redux/actions/sessionActions";
import { ApiService } from "../services/apiService";
import ImageUploader from '../components/ImageUploader';
import { showToastr } from '../services/themeService';
import { sessionLogin } from "../redux/actions/sessionActions";
import { config } from "../config";


const NavbarComponent = ({ dispatch }) => {

  const { user, session } = useSelector((state) => state.session);
  const history = useHistory();
  const [open, setOpen] = useState(false);
  const [image, setImage] = useState(user ? config.UPLOAD_URL + user.id + '.jpg' : '');
  const [profile, setProfile] = useState({
    id: user ? user.id : '',
    name: user ? user.name : '',
    email: user ? user.email : '',
    image: null,
  });

  const handleSignout = () => {
    ApiService.logout(session)
      .then(response => response.json())
      .then(data => {
        console.log(data);

        dispatch(sessionLogout());

        history.push('/admin');
      })
      .catch((err) => {
        console.error(err);
      });
  }

  const handleProfile = () => {
    toggle();
  }

  const handleConfirm = () => {
    ApiService.updateAdmin(session, profile)
      .then(response => response.json())
      .then((data) => {
        console.log(data);

        if (data && data.message == "success") {
          const user = data.user;
          dispatch(sessionLogin(user, session));
          setImage(user ? config.UPLOAD_URL + user.id + '.jpg?' + new Date().getTime() : '');

          showToastr('success', 'User Updated', 'Update Success!');
        } else {
          showToastr('error', 'Update Error', 'User Not Updated!');
        }
      })
      .catch((err) => {
        console.error(err);
      });

    toggle();
  }

  const handleReset = () => {
    ApiService.logout(session)
      .then(response => response.json())
      .then(data => {
        console.log(data);

        dispatch(sessionLogout());

        history.push('/admin/auth/reset-password');
      })
      .catch((err) => {
        console.error(err);
      });
  }

  const toggle = () => {
    setOpen(!open);
  }

  return (
    <React.Fragment>
      <Navbar color="white" light expand>
        <span
          className="sidebar-toggle d-flex mr-2"
          onClick={() => {
            dispatch(toggleSidebar());
          }}
        >
          <i className="hamburger align-self-center" />
        </span>

        <Collapse navbar>
          <Nav className="ml-auto" navbar>
            <UncontrolledDropdown nav inNavbar>
              <span className="d-inline-block d-sm-none">
                <DropdownToggle nav caret>
                  <Settings size={18} className="align-middle" />
                </DropdownToggle>
              </span>
              <span className="d-none d-sm-inline-block">
                <DropdownToggle nav caret>
                  <img
                    src={image}
                    className="avatar img-fluid rounded-circle mr-1"
                    alt="Avatar"
                  />
                  <span className="text-dark">{user ? user.name : ''}</span>
                </DropdownToggle>
              </span>
              <DropdownMenu right>
                <DropdownItem onClick={handleProfile}>
                  <User size={18} className="align-middle mr-2" />
                Profile
                </DropdownItem>
                <DropdownItem divider />
                <DropdownItem>Settings & Privacy</DropdownItem>
                <DropdownItem onClick={handleSignout}>Sign out</DropdownItem>
              </DropdownMenu>
            </UncontrolledDropdown>
          </Nav>
        </Collapse>
      </Navbar>

      <Modal
        isOpen={open}
        toggle={() => toggle()}
        centered
      >
        <ModalHeader toggle={() => toggle()}>
          Profile Settings
        </ModalHeader>
        <ModalBody className="m-3">
          <div className="d-flex justify-content-center">
            <ImageUploader round img={user ? user.id + '.jpg' : ''} onChange={(img) => setProfile({ ...profile, image: img })} />
          </div>
          <h4 className="mt-3">Name</h4>
          <Input
            name="Name"
            onChange={(val) => { setProfile({ ...profile, name: val.target.value }); }}
            value={profile.name}
          />
          <h4 className="mt-3">Email</h4>
          <Input
            name="Name"
            disabled
            onChange={(val) => { setProfile({ ...profile, name: val.target.value }); }}
            value={user ? user.email : ''}
          />
        </ModalBody>
        <ModalFooter style={{ justifyContent: 'space-between' }}>
          <Button color="danger" onClick={() => handleReset()}>
            Reset Pass
          </Button>
          <div>
            <Button color="secondary" onClick={() => toggle()}>
              Cancel
            </Button>{" "}
            <Button color="primary" onClick={() => handleConfirm()}>
              Save
            </Button>
          </div>
        </ModalFooter>
      </Modal>
    </React.Fragment>
  );
};

export default connect(store => ({
  app: store.app
}))(NavbarComponent);
