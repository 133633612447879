import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';

import {
	Button,
	Card,
	CardBody,
	Form,
	FormGroup,
	Label,
	Input,
} from 'reactstrap';
import { ApiService } from '../../services/apiService';
import { showToastr } from '../../services/themeService';

const ForgotPassword = () => {
	const history = useHistory();
	const [email, setEmail] = useState('');

	const handleReset = () => {
		ApiService.forgotPass(email)
			.then((response) => response.json())
			.then((data) => {
				if (data && data.type === 'error') {
					showToastr('error', 'Invalid Email', data.message);

					return;
				}

				showToastr('success', 'Reset Password', data.message);
				history.push('/admin');
			})
			.catch((err) => {
				console.error(err);
			});
	};

	return (
		<React.Fragment>
			<div className='text-center mt-4'>
				<h1 className='h2'>Reset password</h1>
				<p className='lead'>Enter your email to reset your password.</p>
			</div>

			<Card>
				<CardBody>
					<div className='m-sm-4'>
						<Form>
							<FormGroup>
								<Label>Email</Label>
								<Input
									bsSize='lg'
									type='email'
									name='email'
									placeholder='Enter your email'
									value={email}
									onChange={(val) =>
										setEmail(val.target.value)
									}
								/>
							</FormGroup>
							<div className='text-center mt-3'>
								<Button
									color='primary'
									size='lg'
									onClick={handleReset}>
									Reset password
								</Button>
							</div>
						</Form>
					</div>
				</CardBody>
			</Card>
		</React.Fragment>
	);
};

export default ForgotPassword;
