import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import {
  Button,
  Card,
  CardBody,
  Col,
  Container,
  Row,
  Pagination,
  PaginationItem,
  PaginationLink,
  Input,
  Table,
  Spinner,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from "reactstrap";
import Select from "react-select";
import { useHistory } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlusCircle } from "@fortawesome/free-solid-svg-icons";
import { Edit, Trash2, } from "react-feather";

import TableTextCell from '../../../components/TableTextCell';
import { ApiService } from '../../../services/apiService';
import { config } from "../../../config";


const providerOptions = [
  { value: 0, label: "All" },
  { value: 1, label: "AZURE" },
  { value: 2, label: "POLLY" },
  { value: 3, label: "ACAPELA" },
];

const languageOptions = [
  { value: 'en', label: "English" },
  { value: 'es', label: "Spanish" },
  { value: 'fr', label: "French" },
  { value: 'de', label: "German" },
];


const Characters = (props) => {

  const history = useHistory();
  const { session } = useSelector((state) => state.session);
  const { location } = props;
  console.log(location);

  useEffect(() => {
    console.log('123123123123123');
    ApiService.getCharacters(session)
      .then(response => response.json())
      .then(data => {
        if (data && data.message === "Unauthenticated.") {
          history.push('/admin');

          return;
        }

        console.log(data);
        setCharacters(data.characters);
        setVoices(data.voices);
      })
      .catch((err) => {
        console.error(err);
      });
  }, []);

  const [characters, setCharacters] = useState(null);
  const [voices, setVoices] = useState(null);
  const [currentCharacter, setCurrentCharacter] = useState({});
  const [searchValue, setSearchValue] = useState({ name: '', provider: 0, language: '', voice: '', });
  const [page, setPage] = useState(location.state && location.state.page ? location.state.page : 1);
  const [language, setLanguage] = useState(location.state && location.state.language ? languageOptions.find(languageOption => languageOption.value === location.state.language).value : languageOptions[0].value);
  const [open, setOpen] = useState(false);

  const toggle = () => {
    setOpen(!open);
  }

  const handleEdit = (id) => {
    history.push(`/admin/settings/character/${id}`, { page, language });
  }

  const handleDelete = (id) => {
    console.log(id);

    const curCharacter = characters.filter(character => character.id == id);

    if (curCharacter.length > 0)
      setCurrentCharacter(curCharacter[0]);

    toggle();
  }

  const handleConfirm = () => {
    console.log(currentCharacter.id);
    ApiService.deleteCharacter(session, currentCharacter.id)
      .then((data) => {
        console.log(data);
        let tmpCharacters = characters.slice();
        const curCharacter = characters.filter(character => character.id == currentCharacter.id);
        if (curCharacter.length > 0) {
          const index = tmpCharacters.indexOf(curCharacter[0]);
          console.log(curCharacter[0]);
          console.log(index);
          if (index > -1) {
            tmpCharacters.splice(index, 1);
          }
        }
        console.log('Delete');
        console.log(tmpCharacters);

        setCharacters(tmpCharacters);
      })
      .catch((err) => {
        console.error(err);
      });

    toggle();
  }

  const handlePrev = () => {
    if (page == 1)
      return;

    setPage(page - 1);
  }

  const handleNext = () => {
    if (page == pageCount)
      return;

    setPage(page + 1);
  }

  const handlePage = (index) => {
    setPage(index);
  }


  if (!characters || !voices) {
    return (
      <Container fluid className="vh-50 d-flex justify-content-center align-items-center">
        <Spinner color="primary" size="lg" className="mr-2" />
      </Container>
    );
  }


  const filteredCharacters = characters.filter(character =>
    character.name.toLowerCase().includes(searchValue.name.toLowerCase()) &&
    (searchValue.provider == 0 ? true : voices.find(voice => voice.id === character['voice_' + language]).provider == searchValue.provider) &&
    voices.find(voice => voice.id === character['voice_' + language]).language.toLowerCase().includes(searchValue.language.toLowerCase()) &&
    voices.find(voice => voice.id === character['voice_' + language]).voice.toLowerCase().includes(searchValue.voice.toLowerCase())
  );

  let pageCount = 0;
  if (filteredCharacters.length > 0) {
    pageCount = parseInt(filteredCharacters.length / 20) + 1;
  }

  return (
    <Container fluid className="p-0">
      <div className="d-flex align-items-center" style={{ width: '100%', }}>
        <div>
          <h1 className="h3 mb-3">Characters</h1>
          <Button color="primary" className="btn-pill mr-1 mb-3" outline onClick={() => history.push('/admin/settings/character')}>
            <FontAwesomeIcon icon={faPlusCircle} color="primary" className="mr-2" />
            Add New Character
          </Button>
        </div>
        <Select
          className="react-select-container w-50 ml-md-auto"
          classNamePrefix="react-select"
          options={languageOptions}
          isSearchable={false}
          defaultValue={languageOptions.find(languageOption => languageOption.value === language)}
          onChange={(item) => setLanguage(item.value)}
        />
      </div>

      <Row>
        <Col>
          <Card>
            <CardBody>
              <Table bordered style={{ tableLayout: 'fixed' }}>
                <thead>
                  <tr>
                    <th className="text-center" style={{ width: 45 }}>No</th>
                    <th className="text-center" style={{ width: 70 }}>Image</th>
                    <th className="text-center">Name/ID</th>
                    <th className="text-center">Provider</th>
                    <th className="text-center">Language</th>
                    <th className="text-center">Voice</th>
                    <th className="text-center">Translation</th>
                    <th className="text-center" style={{ width: 85 }}>Actions</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td></td>
                    <td></td>
                    <td>
                      <Input
                        name="Search-Name"
                        placeholder="Search..."
                        onChange={(val) => { setSearchValue({ ...searchValue, name: val.target.value }); }}
                        value={searchValue.name}
                      />
                    </td>
                    <td>
                      <Select
                        className="react-select-container"
                        classNamePrefix="react-select"
                        options={providerOptions}
                        isSearchable={false}
                        defaultValue={providerOptions[0]}
                        onChange={(item) => setSearchValue({ ...searchValue, provider: item.value })}
                      />
                    </td>
                    <td>
                      <Input
                        name="Search-Language"
                        placeholder="Search..."
                        onChange={(val) => { setSearchValue({ ...searchValue, language: val.target.value }); }}
                        value={searchValue.language}
                      />
                    </td>
                    <td>
                      <Input
                        name="Search-Voice"
                        placeholder="Search..."
                        onChange={(val) => { setSearchValue({ ...searchValue, voice: val.target.value }); }}
                        value={searchValue.voice}
                      />
                    </td>
                    <td></td>
                    <td></td>
                  </tr>

                  {
                    filteredCharacters.slice((page - 1) * 20, Math.min(page * 20)).map((character, index) => (
                      <tr key={index}>
                        <td className="text-center">{character.id}</td>
                        <td className="text-center">
                          {character.image ? (
                            <img
                              src={config.UPLOAD_URL + character.image + '?' + new Date().getTime()}
                              width="32"
                              height="32"
                              className="rounded-circle my-n1"
                              alt="Flag"
                            />
                          )
                            : null}
                        </td>
                        <td>
                          <TableTextCell>
                            {character.name}
                          </TableTextCell>
                        </td>
                        <td>
                          <TableTextCell>
                            {
                              providerOptions.find(providerOption => providerOption.value == (voices.find(voice => voice.id === character['voice_' + language]).provider)).label
                            }
                          </TableTextCell>
                        </td>
                        <td>
                          <TableTextCell>
                            {
                              voices.find(voice => voice.id === character['voice_' + language]).language
                            }
                          </TableTextCell>
                        </td>
                        <td>
                          <TableTextCell>
                            {
                              voices.find(voice => voice.id === character['voice_' + language]).voice
                            }
                          </TableTextCell>
                        </td>
                        <td>
                          <TableTextCell>
                            {character['trl_' + language]}
                          </TableTextCell>
                        </td>
                        <td className="table-action text-center">
                          <Row className="d-flex justify-content-around" style={{ margin: 'auto', width: 60 }}>
                            <Button className="p-0" color="none" onClick={() => handleEdit(character.id)}>
                              <Edit className="align-middle" size={18} />
                            </Button>
                            <Button className="p-0" color="none" onClick={() => handleDelete(character.id)}>
                              <Trash2 className="align-middle" size={18} />
                            </Button>
                          </Row>
                        </td>
                      </tr>
                    )
                    )}
                </tbody>
              </Table>

              <Row className="justify-content-center">
                <Pagination aria-label="Page navigation" size="lg">
                  <PaginationItem>
                    <PaginationLink previous onClick={() => handlePrev()} />
                  </PaginationItem>
                  {
                    pageCount > 0 ?
                      [...new Array(pageCount)].map((i, index) =>
                        <PaginationItem active={index + 1 == page ? true : false} key={index}>
                          <PaginationLink onClick={() => handlePage(index + 1)}>{index + 1}</PaginationLink>
                        </PaginationItem>)
                      :
                      null
                  }
                  <PaginationItem>
                    <PaginationLink next onClick={() => handleNext()} />
                  </PaginationItem>
                </Pagination>
              </Row>

              <Modal
                isOpen={open}
                toggle={() => toggle()}
                centered
              >
                <ModalHeader toggle={() => toggle()}>
                  Delete character
                </ModalHeader>
                <ModalBody className="text-center m-3">
                  <p className="mb-0">
                    Are you sure want to delete this character?
                  </p>
                </ModalBody>
                <ModalFooter>
                  <Button color="secondary" onClick={() => toggle()}>
                    Cancel
                  </Button>{" "}
                  <Button color="danger" onClick={() => handleConfirm()}>
                    Delete
                  </Button>
                </ModalFooter>
              </Modal>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </Container >
  );
}

export default Characters;
