export const resetPassTranslations = {
	reset_pass: {
		en: 'Password Reset',
		es: 'Restablecer contraseña',
		fr: 'Réinitialiser mot de passe',
		de: 'Passwort zurücksetzen',
	},
	enter_new_pass: {
		en: 'Enter your new password.',
		es: 'Introduce tu nueva contraseña.',
		fr: 'Entrez votre nouveau mot de passe.',
		de: 'Geben Sie Ihr neues Passwort ein.',
	},
	new_pass: {
		en: 'New password',
		es: 'Nueva contraseña',
		fr: 'Nouveau mot de passe',
		de: 'Neues Passwort',
	},
	confirm_pass: {
		en: 'Confirm new password',
		es: 'Confirmar nueva contraseña',
		fr: 'Confirmer le nouveau mot de passe',
		de: 'Neues Passwort bestätigen',
	},
	reset_pass: {
		en: 'Reset Password',
		es: 'Restablecer contraseña',
		fr: 'Réinitialiser mot de passe',
		de: 'Passwort zurücksetzen',
	},
	invalid_req: {
		en: 'Invalid Request',
		es: 'Solicitud no válida',
		fr: 'Requête Invalide',
		de: 'Ungültige Anfrage',
	},
	the_pass_field_req: {
		en: 'The password field is required',
		es: 'El campo de contraseña es obligatorio',
		fr: 'Le champ mot de passe est obligatoire',
		de: 'Das Passwortfeld ist erforderlich',
	},
	min_6_characters: {
		en: 'New password should contain at least 6 characters.',
		es: 'La nueva contraseña debería contener al menos 6 caracteres.',
		fr: 'Le nouveau mot de passe doit contenir au moins 6 caractères.',
		de: 'Das neue Passwort sollte mindestens 6 Zeichen enthalten.',
	},
	pass_mismatch: {
		en: 'Passwords do not match.',
		es: 'Las contraseñas no coinciden.',
		fr: 'Les mots de passe ne correspondent pas.',
		de: 'Die Passwörter stimmen nicht überein.',
	},
	pass_changed: {
		en: 'Password changed',
		es: 'Contraseña cambiada',
		fr: 'Mot de passe changé',
		de: 'Passwort geändert',
	},
	you_can_now_signin: {
		en: 'Now you can log in with your new password.',
		es: 'Ahora puedes iniciar sesión con tu nueva contraseña.',
		fr: 'Vous pouvez maintenant vous connecter avec votre nouveau mot de passe.',
		de: 'Jetzt können Sie sich mit Ihrem neuen Passwort einloggen.',
	},
	invalid_token: {
		en: 'Invalid Token',
		es: 'simbolo no valido',
		fr: 'jeton invalide',
		de: 'Ungültiges Token',
	},
	password_reset_token_invalid: {
		en: 'The password reset token is invalid.',
		es: 'El token de restablecimiento de contraseña no es válido.',
		fr: "Le jeton de réinitialisation du mot de passe n'est pas valide.",
		de: 'Das Token zum Zurücksetzen des Kennworts ist ungültig.',
	},
};
