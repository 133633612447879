import { config } from '../config';

function login(email, pass) {
	const options = {
		method: 'POST',
		headers: {
			Accept: 'application/json',
			'Content-Type': 'application/json;charset=UTF-8',
			'X-Requested-With': 'XMLHttpRequest',
		},
		body: JSON.stringify({
			type: 'admin',
			name: email,
			email: email,
			password: pass,
		}),
	};

	return fetch(config.API_URL + 'auth/login', options);
}

function logout(session) {
	const options = {
		method: 'GET',
		headers: {
			Accept: 'application/json',
			'Content-Type': 'application/json;charset=UTF-8',
			'X-Requested-With': 'XMLHttpRequest',
			Authorization: session,
		},
	};

	return fetch(config.API_URL + 'auth/logout', options);
}

function updateAdmin(session, profile) {
	const data = new FormData();

	console.log(profile.image);
	console.log(typeof profile.image);
	if (typeof profile.image != 'string' && profile.image)
		data.append('file', profile.image);
	data.append('id', profile.id);
	data.append('name', profile.name);
	data.append('email', profile.email);

	const options = {
		method: 'POST',
		headers: {
			Accept: 'application/json',
			Authorization: session,
		},
		body: data,
	};

	return fetch(config.API_URL + 'admin/update', options);
}

function forgotPass(email) {
	const options = {
		method: 'POST',
		headers: {
			Accept: 'application/json',
			'Content-Type': 'application/json;charset=UTF-8',
			'X-Requested-With': 'XMLHttpRequest',
		},
		body: JSON.stringify({
			email: email,
		}),
	};

	return fetch(config.API_URL + 'pass/forgot', options);
}

function findToken(token) {
	const options = {
		method: 'GET',
		headers: {
			Accept: 'application/json',
			'Content-Type': 'application/json;charset=UTF-8',
			'X-Requested-With': 'XMLHttpRequest',
		},
	};

	return fetch(config.API_URL + 'pass/find/' + token, options);
}

function resetPass(email, pass, confirmPass, token) {
	const options = {
		method: 'POST',
		headers: {
			Accept: 'application/json',
			'Content-Type': 'application/json;charset=UTF-8',
			'X-Requested-With': 'XMLHttpRequest',
		},
		body: JSON.stringify({
			email: email,
			password: pass,
			password_confirmation: confirmPass,
			token: token,
		}),
	};

	return fetch(config.API_URL + 'pass/reset', options);
}

function confirmEmail(token) {
	const options = {
		method: 'GET',
		headers: {
			Accept: 'application/json',
			'Content-Type': 'application/json;charset=UTF-8',
			'X-Requested-With': 'XMLHttpRequest',
		},
	};

	return fetch(config.API_URL + 'auth/confirm/' + token, options);
}

function getTranslations(session) {
	const options = {
		method: 'GET',
		headers: {
			Accept: 'application/json',
			'Content-Type': 'application/json;charset=UTF-8',
			'X-Requested-With': 'XMLHttpRequest',
			Authorization: session,
		},
	};

	return fetch(config.API_URL + 'translation/all', options);
}

function deleteTranslation(session, id) {
	const options = {
		method: 'DELETE',
		headers: {
			Accept: 'application/json',
			'Content-Type': 'application/json;charset=UTF-8',
			'X-Requested-With': 'XMLHttpRequest',
			Authorization: session,
		},
		body: JSON.stringify({
			id: id,
		}),
	};

	return fetch(config.API_URL + 'translation/delete', options);
}

function updateTranslation(session, id, translation) {
	const options = {
		method: 'PUT',
		headers: {
			Accept: 'application/json',
			'Content-Type': 'application/json;charset=UTF-8',
			'X-Requested-With': 'XMLHttpRequest',
			Authorization: session,
		},
		body: JSON.stringify({
			id: id,
			translation: translation,
		}),
	};

	return fetch(config.API_URL + 'translation/update', options);
}

function getUsers(session) {
	const options = {
		method: 'GET',
		headers: {
			Accept: 'application/json',
			'Content-Type': 'application/json;charset=UTF-8',
			'X-Requested-With': 'XMLHttpRequest',
			Authorization: session,
		},
	};

	return fetch(config.API_URL + 'user/all', options);
}

function deleteUser(session, id) {
	const options = {
		method: 'DELETE',
		headers: {
			Accept: 'application/json',
			'Content-Type': 'application/json;charset=UTF-8',
			'X-Requested-With': 'XMLHttpRequest',
			Authorization: session,
		},
		body: JSON.stringify({
			id: id,
		}),
	};

	return fetch(config.API_URL + 'user/delete', options);
}

function updateUser(session, id, user) {
	const options = {
		method: 'PUT',
		headers: {
			Accept: 'application/json',
			'Content-Type': 'application/json;charset=UTF-8',
			'X-Requested-With': 'XMLHttpRequest',
			Authorization: session,
		},
		body: JSON.stringify({
			id: id,
			user: user,
		}),
	};

	return fetch(config.API_URL + 'user/update', options);
}

function getLevels(session) {
	const options = {
		method: 'GET',
		headers: {
			Accept: 'application/json',
			'Content-Type': 'application/json;charset=UTF-8',
			'X-Requested-With': 'XMLHttpRequest',
			Authorization: session,
		},
	};

	return fetch(config.API_URL + 'level/all', options);
}

function getLevel(session, id) {
	const options = {
		method: 'GET',
		headers: {
			Accept: 'application/json',
			'Content-Type': 'application/json;charset=UTF-8',
			'X-Requested-With': 'XMLHttpRequest',
			Authorization: session,
		},
	};

	return fetch(config.API_URL + `level/${id}`, options);
}

function deleteLevel(session, id) {
	const options = {
		method: 'DELETE',
		headers: {
			Accept: 'application/json',
			'Content-Type': 'application/json;charset=UTF-8',
			'X-Requested-With': 'XMLHttpRequest',
			Authorization: session,
		},
		body: JSON.stringify({
			id: id,
		}),
	};

	return fetch(config.API_URL + 'level/delete', options);
}

function updateLevel(session, id, level, lang) {
	const options = {
		method: 'PUT',
		headers: {
			Accept: 'application/json',
			'Content-Type': 'application/json;charset=UTF-8',
			'X-Requested-With': 'XMLHttpRequest',
			Authorization: session,
		},
		body: JSON.stringify({
			id: id,
			level: level,
			lang: lang,
		}),
	};

	return fetch(config.API_URL + 'level/update', options);
}

function updateAdFrequency(frequency) {
	const data = new FormData();
	data.append('ad_frequency', frequency.value);
	const options = {
		method: 'POST',
		headers: {
			Accept: 'application/json',
		},
		body: data,
	};

	return fetch(config.API_URL + 'open/updateAdFrequency', options);
}

function getChapters(session) {
	const options = {
		method: 'GET',
		headers: {
			Accept: 'application/json',
			'Content-Type': 'application/json;charset=UTF-8',
			'X-Requested-With': 'XMLHttpRequest',
			Authorization: session,
		},
	};

	return fetch(config.API_URL + 'chapter/all', options);
}

function getAdFrequency() {
	const options = {
		method: 'GET',
		headers: {
			Accept: 'application/json',
			'Content-Type': 'application/json;charset=UTF-8',
			'X-Requested-With': 'XMLHttpRequest',
		},
	};

	return fetch(config.API_URL + 'open/getAdFrequency', options);
}

function getChapter(session, id) {
	const options = {
		method: 'GET',
		headers: {
			Accept: 'application/json',
			'Content-Type': 'application/json;charset=UTF-8',
			'X-Requested-With': 'XMLHttpRequest',
			Authorization: session,
		},
	};

	return fetch(config.API_URL + `chapter/${id}`, options);
}

function deleteChapter(session, id) {
	const options = {
		method: 'DELETE',
		headers: {
			Accept: 'application/json',
			'Content-Type': 'application/json;charset=UTF-8',
			'X-Requested-With': 'XMLHttpRequest',
			Authorization: session,
		},
		body: JSON.stringify({
			id: id,
		}),
	};

	return fetch(config.API_URL + 'chapter/delete', options);
}

function updateChapter(session, id, chapter, level, status) {
	const data = new FormData();

	data.append('id', id ? id : 0);
	console.log(chapter.image);
	console.log(typeof chapter.image);
	if (typeof chapter.image != 'string' && chapter.image)
		data.append('file', chapter.image);
	data.append('en', chapter.en);
	data.append('es', chapter.es);
	data.append('fr', chapter.fr);
	data.append('de', chapter.de);
	data.append('level', level);
	data.append('status', status);

	const options = {
		method: 'POST',
		headers: {
			Accept: 'application/json',
			Authorization: session,
		},
		body: data,
	};

	return fetch(config.API_URL + 'chapter/update', options);
}

function getLanguages(session) {
	const options = {
		method: 'GET',
		headers: {
			Accept: 'application/json',
			'Content-Type': 'application/json;charset=UTF-8',
			'X-Requested-With': 'XMLHttpRequest',
			Authorization: session,
		},
	};

	return fetch(config.API_URL + 'language/all', options);
}

function getLanguage(session, id) {
	const options = {
		method: 'GET',
		headers: {
			Accept: 'application/json',
			'Content-Type': 'application/json;charset=UTF-8',
			'X-Requested-With': 'XMLHttpRequest',
			Authorization: session,
		},
	};

	return fetch(config.API_URL + `language/${id}`, options);
}

function deleteLanguage(session, id) {
	const options = {
		method: 'DELETE',
		headers: {
			Accept: 'application/json',
			'Content-Type': 'application/json;charset=UTF-8',
			'X-Requested-With': 'XMLHttpRequest',
			Authorization: session,
		},
		body: JSON.stringify({
			id: id,
		}),
	};

	return fetch(config.API_URL + 'language/delete', options);
}

function updateLanguage(session, id, language, status) {
	const data = new FormData();

	data.append('id', id ? id : 0);
	console.log(language.image);
	console.log(typeof language.image);
	if (typeof language.image != 'string' && language.image)
		data.append('file', language.image);
	data.append('title', language.title);
	data.append('code', language.code);
	data.append('status', status);

	const options = {
		method: 'POST',
		headers: {
			Accept: 'application/json',
			Authorization: session,
		},
		body: data,
	};

	return fetch(config.API_URL + 'language/update', options);
}

function getCharacters(session) {
	const options = {
		method: 'GET',
		headers: {
			Accept: 'application/json',
			'Content-Type': 'application/json;charset=UTF-8',
			'X-Requested-With': 'XMLHttpRequest',
			Authorization: session,
		},
	};

	return fetch(config.API_URL + 'character/all', options);
}

function getCharacter(session, id) {
	const options = {
		method: 'GET',
		headers: {
			Accept: 'application/json',
			'Content-Type': 'application/json;charset=UTF-8',
			'X-Requested-With': 'XMLHttpRequest',
			Authorization: session,
		},
	};

	return fetch(config.API_URL + `character/${id}`, options);
}

function deleteCharacter(session, id) {
	const options = {
		method: 'DELETE',
		headers: {
			Accept: 'application/json',
			'Content-Type': 'application/json;charset=UTF-8',
			'X-Requested-With': 'XMLHttpRequest',
			Authorization: session,
		},
		body: JSON.stringify({
			id: id,
		}),
	};

	return fetch(config.API_URL + 'character/delete', options);
}

function updateCharacter(session, id, character) {
	console.log('character=======================');
	console.log(character);
	const data = new FormData();

	data.append('id', id ? id : 0);
	console.log(character.image);
	console.log(typeof character.image);
	data.append('file', character.image);
	data.append('name', character.name);
	data.append('isEnemy', character.isEnemy);
	data.append('trl_en', character.trl_en);
	data.append('trl_es', character.trl_es);
	data.append('trl_fr', character.trl_fr);
	data.append('trl_de', character.trl_de);
	data.append('voice_en', character.voice_en);
	data.append('voice_es', character.voice_es);
	data.append('voice_fr', character.voice_fr);
	data.append('voice_de', character.voice_de);

	data.append('title_en', character.title_en);
	data.append('title_es', character.title_es);
	data.append('title_fr', character.title_fr);
	data.append('title_de', character.title_de);

	data.append('desc_en', character.desc_en);
	data.append('desc_es', character.desc_es);
	data.append('desc_fr', character.desc_fr);
	data.append('desc_de', character.desc_de);

	const options = {
		method: 'POST',
		headers: {
			Accept: 'application/json',
			Authorization: session,
		},
		body: data,
	};

	return fetch(config.API_URL + 'character/update', options);
}

function getActivities(session) {
	const options = {
		method: 'GET',
		headers: {
			Accept: 'application/json',
			'Content-Type': 'application/json;charset=UTF-8',
			'X-Requested-With': 'XMLHttpRequest',
			Authorization: session,
		},
	};

	return fetch(config.API_URL + 'activity/all', options);
}

function getActivity(session, id) {
	const options = {
		method: 'GET',
		headers: {
			Accept: 'application/json',
			'Content-Type': 'application/json;charset=UTF-8',
			'X-Requested-With': 'XMLHttpRequest',
			Authorization: session,
		},
	};

	return fetch(config.API_URL + `activity/${id}`, options);
}

function deleteActivity(session, id) {
	const options = {
		method: 'DELETE',
		headers: {
			Accept: 'application/json',
			'Content-Type': 'application/json;charset=UTF-8',
			'X-Requested-With': 'XMLHttpRequest',
			Authorization: session,
		},
		body: JSON.stringify({
			id: id,
		}),
	};

	return fetch(config.API_URL + 'activity/delete', options);
}

function updateActivity(session, id, activity, exercises, sync) {
	console.log('activity=======================');
	console.log(activity);
	const data = new FormData();

	data.append('id', id ? id : 0);
	data.append('sync', sync ? 1 : 0);
	data.append('image', activity.image);
	data.append('title_en', activity.title_en);
	data.append('title_es', activity.title_es);
	data.append('title_fr', activity.title_fr);
	data.append('title_de', activity.title_de);
	data.append('chapter_id', activity.chapter_id);
	data.append('google_sheet_url', activity.google_sheet_url);
	data.append('sheet_name', activity.sheet_name);
	data.append('image_male1', activity.image_male1);
	data.append('image_male2', activity.image_male2);
	data.append('image_female1', activity.image_female1);
	data.append('image_female2', activity.image_female2);
	data.append('image_acapelam', activity.image_acapelam);
	data.append('image_acapelaf', activity.image_acapelaf);
	data.append('link', activity.link);
	let i = 1;
	exercises.map((exercise, index) => {
		if (exercise.type === 0) {
			data.append(`image_${index}`, exercise.file);
			i++;
			// exercises.splice(index, 1);
		}
		if (exercise.provider === 3) {
			data.append(`acapela_${index}`, exercise.file);
		}
	});
	data.append('exercises', JSON.stringify(exercises));

	console.log(data);

	const options = {
		method: 'POST',
		headers: {
			Accept: 'application/json',
			Authorization: session,
		},
		body: data,
	};

	return fetch(config.API_URL + 'activity/update', options);
}

function updateExercises(session, exercises) {
	console.log('exercises=======================');
	console.log(exercises);
	const data = new FormData();
	const ids = exercises.map((exercise) => exercise.id);
	console.log(ids);
	data.append('ids', JSON.stringify(ids));
	exercises.map((exercise) => {
		data.append(`file_${exercise.id}`, exercise.file);
	});

	const options = {
		method: 'POST',
		headers: {
			Accept: 'application/json',
			Authorization: session,
		},
		body: data,
	};

	return fetch(config.API_URL + 'exercise/update', options);
}

export const ApiService = {
	login,
	logout,
	updateAdmin,
	forgotPass,
	findToken,
	resetPass,
	confirmEmail,
	getTranslations,
	deleteTranslation,
	updateTranslation,
	getUsers,
	deleteUser,
	updateUser,
	getLevels,
	getLevel,
	deleteLevel,
	updateLevel,
	getChapters,
	getChapter,
	deleteChapter,
	updateChapter,
	getLanguages,
	getLanguage,
	deleteLanguage,
	updateLanguage,
	getCharacters,
	getCharacter,
	deleteCharacter,
	updateCharacter,
	getActivities,
	getActivity,
	deleteActivity,
	updateActivity,
	updateExercises,
	updateAdFrequency,
	getAdFrequency,
};
