import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import {
	Button,
	Card,
	CardBody,
	Form,
	FormGroup,
	Label,
	Input,
} from 'reactstrap';
import { ApiService } from '../../services/apiService';
import { showToastr } from '../../services/themeService';
import { resetPassTranslations } from '../../services/translations';
import { ResetPassSuccess } from './ResetPassSuccess';

const ResetPassword = (props) => {
	const { token, lang } = props.match.params;

	const history = useHistory();
	const [pass, setPass] = useState('');
	const [confirmPass, setConfirmPass] = useState('');
	const [error, setError] = useState('');
	const [success, setSuccess] = useState(false);

	const handleReset = () => {
		if (pass.length < 6)
			return setError(resetPassTranslations.min_6_characters[lang]);

		if (confirmPass !== pass)
			return setError(resetPassTranslations.pass_mismatch[lang]);

		ApiService.findToken(token)
			.then((response) => response.json())
			.then((data) => {
				if (data && data.type === 'error') {
					showToastr(
						'error',
						resetPassTranslations.invalid_token[lang],
						resetPassTranslations.password_reset_token_invalid[lang]
					);

					return;
				}

				const passReset = data.passwordReset;
				ApiService.resetPass(passReset.email, pass, confirmPass, token)
					.then((response) => response.json())
					.then((data) => {
						if (data && data.type === 'error') {
							showToastr(
								'error',
								resetPassTranslations.invalid_req[lang],
								resetPassTranslations.invalid_req[data.message]
							);

							return;
						} else {
							setError('');
							setSuccess(true);
							return;
						}
					})
					.catch((err) => {
						console.error(err);
					});
			})
			.catch((err) => {
				console.error(err);
			});
	};

	return success ? (
		<ResetPassSuccess
			title={resetPassTranslations.pass_changed[lang]}
			message={resetPassTranslations.you_can_now_signin[lang]}
		/>
	) : (
		<React.Fragment>
			<div className='text-center mt-4'>
				<h1 className='h2'>{resetPassTranslations.reset_pass[lang]}</h1>
				<p className='lead'>
					{resetPassTranslations.enter_new_pass[lang]}
				</p>
			</div>

			<Card>
				<CardBody>
					<div className='m-sm-4'>
						<Form>
							<FormGroup>
								<Input
									bsSize='lg'
									type='password'
									name='pass'
									placeholder={
										resetPassTranslations.new_pass[lang]
									}
									value={pass}
									onChange={(val) =>
										setPass(val.target.value)
									}
								/>
							</FormGroup>
							<FormGroup>
								<Input
									bsSize='lg'
									type='password'
									name='confirm-pass'
									placeholder={
										resetPassTranslations.confirm_pass[lang]
									}
									value={confirmPass}
									onChange={(val) =>
										setConfirmPass(val.target.value)
									}
								/>
							</FormGroup>
							<span style={{ color: 'red' }}>{error}</span>
							<div className='text-center mt-3'>
								<Button
									color='primary'
									size='lg'
									onClick={handleReset}>
									{resetPassTranslations.reset_pass[lang]}
								</Button>
							</div>
						</Form>
					</div>
				</CardBody>
			</Card>
		</React.Fragment>
	);
};

export default ResetPassword;
