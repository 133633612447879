import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import {
  Button,
  Col,
  Container,
  Row,
  Input,
  Spinner,
} from "reactstrap";
import Select from "react-select";
import { useHistory } from "react-router-dom";

import { ApiService } from '../../../services/apiService';
import ImageUploader from '../../../components/ImageUploader';
import { showToastr } from '../../../services/themeService';


const statusOptions = [
  { value: 1, label: "Draft" },
  { value: 2, label: "Published" },
];


const ChapterEdit = (props) => {

  const history = useHistory();
  const { session } = useSelector((state) => state.session);
  const page = props.location.state;

  const { id } = props.match.params;

  const [chapter, setChapter] = useState(null);
  const [languages, setLanguages] = useState([]);
  const [allLevels, setAllLevels] = useState([]);
  const [levels, setLevels] = useState([]);
  const [open, setOpen] = useState(false);
  const [lang, setLang] = useState(null);
  const [level, setLevel] = useState(null);
  const [status, setStatus] = useState(2);

  useEffect(() => {
    ApiService.getChapter(session, id)
      .then(response => response.json())
      .then(data => {
        if (data && data.message === "Unauthenticated.") {
          history.push('/admin');

          return;
        }

        const { chapter, langs, levels } = data;
        if (id && !chapter) {
          setStatus(null);

          return;
        }

        let languages = new Array();

        langs.map((lang) => {
          languages.push({
            value: lang.id,
            label: lang.code,
          })
        });

        setLanguages(languages);

        setAllLevels(levels);

        if (!chapter) {
          setChapter({
            en: '',
            es: '',
            fr: '',
            de: '',
            image: '',
          });

          setStatus(statusOptions[1]);

          return;
        }

        const filteredLevels = levels.filter(level =>
          level.language_id == chapter.language_id
        );

        let levelsArray = new Array();

        filteredLevels.map((level) => {
          levelsArray.push({
            value: level.id,
            label: level.level,
          })
        });

        setLevels(levelsArray);

        setChapter({
          en: chapter.en,
          es: chapter.es,
          fr: chapter.fr,
          de: chapter.de,
          image: chapter.image,
        });

        setStatus(statusOptions.filter(option =>
          option.value == chapter.status
        )[0]);

        setLevel(levelsArray.filter(level =>
          level.value == chapter.level_id
        )[0]);

        setLang(languages[chapter.language_id - 1]);
      })
      .catch((err) => {
        console.error(err);
      });
  }, []);

  const toggle = () => {
    setOpen(!open);
  }

  const handleSave = () => {
    if (!chapter.en || !chapter.es || !chapter.fr || !chapter.de || !level) {
      showToastr('warning', 'Fields Required', 'Please input fields.');

      return;
    }

    console.log(id);
    console.log(chapter);
    console.log(level.value);
    console.log(status.value);
    ApiService.updateChapter(session, id, chapter, level.value, status.value)
      .then((response) => {
        history.push('/admin/journeys/chapters', page ? page : '');
      })
      .catch((err) => {
        console.error(err);
      });

    toggle();
  }

  const handleLanguageChange = (item) => {
    setLang(item);

    const filteredLevels = allLevels.filter(level =>
      level.language_id == item.value
    );

    let levelsArray = new Array();

    filteredLevels.map((level) => {
      levelsArray.push({
        value: level.id,
        label: level.level,
      })
    });

    setLevels(levelsArray);

    setLevel(levelsArray[0]);
  }


  if (!chapter && status) {
    return (
      <Container fluid className="vh-50 d-flex justify-content-center align-items-center">
        <Spinner color="primary" size="lg" className="mr-2" />
      </Container>
    );
  }

  if (id && !chapter && !status) {
    return (
      <Container fluid className="vh-50 d-flex justify-content-center align-items-center">
        <h1>No Level Found!</h1>
      </Container>
    );
  }

  return (
    <Container fluid className="p-0">
      <h1 className="h3 mb-3">Chapter</h1>

      <Row>
        <Col>
          <h5>Languages</h5>
          <Select
            className="react-select-container"
            classNamePrefix="react-select"
            options={languages}
            isSearchable={false}
            onChange={(item) => handleLanguageChange(item)}
            value={lang}
          />
          <h5 className="mt-3">Levels</h5>
          <Select
            className="react-select-container"
            classNamePrefix="react-select"
            options={levels}
            isSearchable={false}
            onChange={(item) => setLevel(item)}
            value={level}
          />
          <h5 className="mt-3">Title</h5>
          <div className="mt-3 d-flex justify-content-between">
            <div style={{ width: '24%' }}>
              <p style={{ fontSize: 12, marginBottom: 5 }}>English</p>
              <Input
                name="Search-Level"
                onChange={(val) => { setChapter({ ...chapter, en: val.target.value }); }}
                value={chapter.en}
              />
            </div>
            <div style={{ width: '24%' }}>
              <p style={{ fontSize: 12, marginBottom: 5 }}>Spanish</p>
              <Input
                name="Search-Level"
                onChange={(val) => { setChapter({ ...chapter, es: val.target.value }); }}
                value={chapter.es}
              />
            </div>
            <div style={{ width: '24%' }}>
              <p style={{ fontSize: 12, marginBottom: 5 }}>French</p>
              <Input
                name="Search-Level"
                onChange={(val) => { setChapter({ ...chapter, fr: val.target.value }); }}
                value={chapter.fr}
              />
            </div>
            <div style={{ width: '24%' }}>
              <p style={{ fontSize: 12, marginBottom: 5 }}>German</p>
              <Input
                name="Search-Level"
                onChange={(val) => { setChapter({ ...chapter, de: val.target.value }); }}
                value={chapter.de}
              />
            </div>
          </div>
          <h5 className="mt-3">Image</h5>
          <ImageUploader img={chapter.image + '?' + new Date().getTime()} onChange={(img) => setChapter({ ...chapter, image: img })} />
          <h5 className="mt-3">Status</h5>
          <Select
            className="react-select-container"
            classNamePrefix="react-select"
            options={statusOptions}
            isSearchable={false}
            onChange={(item) => setStatus(item)}
            value={status}
          />
          <Row className="mt-7 justify-content-center">
            <Button color="secondary" className="mr-3" onClick={() => history.push('/admin/journeys/chapters', page ? page : '')}>Cancel</Button>
            <Button color="primary" onClick={() => handleSave()}>Save</Button>
          </Row>
        </Col>
      </Row>
    </Container >
  );
}

export default ChapterEdit;
