import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import {
	Button,
	Card,
	CardBody,
	Col,
	Container,
	Row,
	Input,
	Table,
	Spinner,
	Modal,
	ModalHeader,
	ModalBody,
	ModalFooter,
} from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlusCircle } from '@fortawesome/free-solid-svg-icons';
import { Edit, Trash2 } from 'react-feather';

import TableTextCell from '../../../components/TableTextCell';
import CustomPagination from '../../../components/CustomPagination';
import { ApiService } from '../../../services/apiService';
import { config } from '../../../config';
import { activitiesPage } from '../../../redux/actions/appActions';

const Activities = (props) => {
	const history = useHistory();
	const dispatch = useDispatch();

	const { session } = useSelector((state) => state.session);
	const { activities_page } = useSelector((state) => state.app);

	const [activities, setActivities] = useState(null);
	const [currentActivity, setCurrentActivity] = useState({});
	const [searchValue, setSearchValue] = useState({
		id: '',
		language: '',
		level: '',
		chapter: '',
		title: '',
	});
	const [page, setPage] = useState(activities_page || 1);

	const [open, setOpen] = useState(false);

	useEffect(() => {
		ApiService.getActivities(session)
			.then((response) => response.json())
			.then((data) => {
				if (data && data.message === 'Unauthenticated.') {
					history.push('/admin');

					return;
				}

				setActivities(data);
			})
			.catch((err) => {
				console.error(err);
			});
	}, []);

	useEffect(() => {
		dispatch(activitiesPage(page));
	}, [page]);

	const toggle = () => {
		setOpen(!open);
	};

	const handleEdit = (id) => {
		history.push(`/admin/journeys/activity/${id}`);
	};

	const handleDelete = (id) => {
		const curActivity = activities.filter((activity) => activity.id == id);

		if (curActivity.length > 0) setCurrentActivity(curActivity[0]);

		toggle();
	};

	const handleConfirm = () => {
		setActivities(null);
		ApiService.deleteActivity(session, currentActivity.id)
			.then((data) => {
				console.log(data);
				let tmpActivities = activities.slice();
				const curActivity = activities.filter(
					(activity) => activity.id == currentActivity.id
				);
				if (curActivity.length > 0) {
					const index = tmpActivities.indexOf(curActivity[0]);
					console.log(curActivity[0]);
					console.log(index);
					if (index > -1) {
						tmpActivities.splice(index, 1);
					}
				}
				console.log('Delete');
				console.log(tmpActivities);

				setActivities(tmpActivities);
			})
			.catch((err) => {
				console.error(err);
			});

		toggle();
	};

	const filteredActivities =
		activities && activities.length > 0
			? activities.filter(
					(activity) =>
						activity.id
							.toString()
							.includes(searchValue.id.toLowerCase()) &&
						activity.language.code
							.toLowerCase()
							.includes(searchValue.language.toLowerCase()) &&
						activity.level.level
							.toLowerCase()
							.includes(searchValue.level.toLowerCase()) &&
						activity.chapter[activity.language.code]
							.toLowerCase()
							.includes(searchValue.chapter.toLowerCase()) &&
						activity[`title_${activity.language.code}`]
							.toLowerCase()
							.includes(searchValue.title.toLowerCase())
			  )
			: new Array();

	let pageCount = 0;
	if (filteredActivities.length > 0) {
		pageCount = Math.ceil(parseFloat(filteredActivities.length) / 20);
	}

	const handleChangePage = (index) => {
		setPage(index);
	};

	const handleNew = () => {
		setPage(pageCount);
		dispatch(activitiesPage(pageCount));
		history.push('/admin/journeys/activity');
	};

	if (!activities) {
		return (
			<Container
				fluid
				className='vh-50 d-flex justify-content-center align-items-center'>
				<Spinner color='primary' size='lg' className='mr-2' />
			</Container>
		);
	}

	return (
		<Container fluid className='p-0'>
			<h1 className='h3 mb-3'>Journey Activities</h1>
			<Button
				color='primary'
				className='btn-pill mr-1 mb-3'
				outline
				onClick={handleNew}>
				<FontAwesomeIcon
					icon={faPlusCircle}
					color='primary'
					className='mr-2'
				/>
				Add New Activity
			</Button>

			<Row>
				<Col>
					<Card>
						{/* <CardHeader>
              <CardTitle tag="h5" className="mb-0">Empty card</CardTitle>
            </CardHeader> */}
						<CardBody>
							<Table bordered style={{ tableLayout: 'fixed' }}>
								<thead>
									<tr>
										<th
											className='text-center'
											style={{ width: 60 }}>
											No
										</th>
										<th
											className='text-center'
											style={{ width: 70 }}>
											Image
										</th>
										<th className='text-center'>
											Language
										</th>
										<th className='text-center'>Level</th>
										<th className='text-center'>Chapter</th>
										<th className='text-center'>Title</th>
										<th
											className='text-center'
											style={{ width: 85 }}>
											Actions
										</th>
									</tr>
								</thead>
								<tbody>
									<tr>
										<td>
											<Input
												name='Search-No'
												placeholder='Search...'
												onChange={(val) => {
													setSearchValue({
														...searchValue,
														id: val.target.value,
													});
													setPage(1);
												}}
												value={searchValue.id}
											/>
										</td>
										<td></td>
										<td>
											<Input
												name='Search-Lang'
												placeholder='Search...'
												onChange={(val) => {
													setSearchValue({
														...searchValue,
														language:
															val.target.value,
													});
													setPage(1);
												}}
												value={searchValue.language}
											/>
										</td>
										<td>
											<Input
												name='Search-Level'
												placeholder='Search...'
												onChange={(val) => {
													setSearchValue({
														...searchValue,
														level: val.target.value,
													});
													setPage(1);
												}}
												value={searchValue.level}
											/>
										</td>
										<td>
											<Input
												name='Search-Chapter'
												placeholder='Search...'
												onChange={(val) => {
													setSearchValue({
														...searchValue,
														chapter:
															val.target.value,
													});
													setPage(1);
												}}
												value={searchValue.chapter}
											/>
										</td>
										<td>
											<Input
												name='Search-Title'
												placeholder='Search...'
												onChange={(val) => {
													setSearchValue({
														...searchValue,
														title: val.target.value,
													});
													setPage(1);
												}}
												value={searchValue.title}
											/>
										</td>
										<td></td>
									</tr>

									{filteredActivities
										.slice(
											(page - 1) * 20,
											Math.min(
												page * 20,
												filteredActivities.length
											)
										)
										.map((activity, index) => (
											<tr key={index}>
												<td className='text-center'>
													{activity.id}
												</td>
												<td className='text-center'>
													{activity.image ? (
														<img
															src={
																config.UPLOAD_URL +
																activity.image
															}
															width='32'
															height='32'
															className='rounded-circle my-n1'
															alt='Flag'
														/>
													) : null}
												</td>
												<td>
													<TableTextCell>
														{activity.language.code}
													</TableTextCell>
												</td>
												<td>
													<TableTextCell>
														{activity.level.level}
													</TableTextCell>
												</td>
												<td>
													<TableTextCell>
														{
															activity.chapter[
																activity
																	.language
																	.code
															]
														}
													</TableTextCell>
												</td>
												<td>
													<TableTextCell>
														{
															activity[
																`title_${activity.language.code}`
															]
														}
													</TableTextCell>
												</td>
												<td className='table-action text-center'>
													<Row
														className='d-flex justify-content-around'
														style={{
															margin: 'auto',
															width: 60,
														}}>
														<Button
															className='p-0'
															color='none'
															onClick={() =>
																handleEdit(
																	activity.id
																)
															}>
															<Edit
																className='align-middle'
																size={18}
															/>
														</Button>
														<Button
															className='p-0'
															color='none'
															onClick={() =>
																handleDelete(
																	activity.id
																)
															}>
															<Trash2
																className='align-middle'
																size={18}
															/>
														</Button>
													</Row>
												</td>
											</tr>
										))}
								</tbody>
							</Table>

							<Row className='justify-content-center'>
								<CustomPagination
									pageCount={pageCount}
									page={page}
									onChangePage={(page) =>
										handleChangePage(page)
									}
								/>
							</Row>

							<Modal
								isOpen={open}
								toggle={() => toggle()}
								centered>
								<ModalHeader toggle={() => toggle()}>
									Delete activity
								</ModalHeader>
								<ModalBody className='text-center m-3'>
									<p className='mb-0'>
										Are you sure want to delete this
										activity?
									</p>
								</ModalBody>
								<ModalFooter>
									<Button
										color='secondary'
										onClick={() => toggle()}>
										Cancel
									</Button>{' '}
									<Button
										color='danger'
										onClick={() => handleConfirm()}>
										Delete
									</Button>
								</ModalFooter>
							</Modal>
						</CardBody>
					</Card>
				</Col>
			</Row>
		</Container>
	);
};

export default Activities;
