import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Edit, Save, X } from 'react-feather';
import {
	Badge,
	Card,
	CardBody,
	CardHeader,
	CardTitle,
	Col,
	Container,
	Row,
	Table,
	Button,
	Spinner,
	Input,
	Label,
	UncontrolledButtonDropdown,
	DropdownItem,
	DropdownMenu,
	DropdownToggle,
	Pagination,
	PaginationItem,
	PaginationLink,
} from 'reactstrap';
import { useHistory } from 'react-router-dom';

import TableTextCell from '../../components/TableTextCell';
import { ApiService } from '../../services/apiService';
import { config } from '../../config';

import male_avatar from '../../assets/img/avatars/male_avatar.jpg';
import female_avatar from '../../assets/img/avatars/female_avatar.jpg';
import CustomPagination from '../../components/CustomPagination';

const Users = () => {
	const { session } = useSelector((state) => state.session);
	const history = useHistory();

	const [users, setUsers] = useState(null);
	const [currentId, setCurrentId] = useState(-1);
	const [currentUser, setCurrentUser] = useState({});
	const [searchValue, setSearchValue] = useState({ name: '', email: '' });
	const [page, setPage] = useState(1);
	const [allCheck, setAllCheck] = useState(false);
	const [check, setCheck] = useState(null);
	const [delDisable, setDelDisable] = useState(true);

	const [open, setOpen] = useState(false);

	useEffect(() => {
		ApiService.getUsers(session)
			.then((response) => response.json())
			.then((data) => {
				console.log(data);
				if (data && data.message === 'Unauthenticated.') {
					history.push('/admin');

					return;
				}

				setUsers(data);

				let checkArray = new Array();
				data.map((user) => {
					checkArray.push(false);
				});

				setCheck(checkArray);
			})
			.catch((err) => {
				console.error(err);
			});
	}, []);

	useEffect(() => {
		if (!check) return;

		let checkArray = check.slice(0);
		let allChecked = true;
		let delDisable = true;
		for (
			let i = (page - 1) * 20;
			i < Math.min(page * 20, users.length);
			i++
		) {
			if (!checkArray[i]) allChecked = false;
			else delDisable = false;
		}

		setAllCheck(allChecked);
		setDelDisable(delDisable);
	}, [page]);

	const toggle = () => {
		setOpen(!open);
	};

	const handleEdit = (id, index) => {
		setCurrentId(index);

		const curUser = users.filter((user) => user.id == id);

		if (curUser.length > 0) setCurrentUser(curUser[0]);
	};

	const handleDelete = () => {
		let idArray = new Array();
		for (let i = 0; i < check.length; i++) {
			if (check[i]) {
				idArray.push(users[i].id);
			}
		}

		ApiService.deleteUser(session, idArray.join())
			.then((response) => response.json())
			.then((data) => {
				let tmpUsers = users.slice();
				tmpUsers = tmpUsers.filter(
					(user) => !idArray.includes(user.id)
				);

				setUsers(tmpUsers);
				let checkArray = check.slice();
				for (
					let i = (page - 1) * 20;
					i < Math.min(page * 20, users.length);
					i++
				) {
					checkArray[i] = false;
				}

				setCheck(checkArray);
			})
			.catch((err) => {
				console.error(err);
			});

		setCurrentId(-1);
	};

	const handleSave = (id) => {
		ApiService.updateUser(session, id, currentUser)
			.then((response) => response.json())
			.then((data) => {
				console.log(data);

				const curUser = users.filter(
					(trnaslation) => trnaslation.id == id
				);
				if (curUser.length > 0) {
					const index = users.indexOf(curUser[0]);
					const tmpUsers = users.slice();
					tmpUsers[index].name = currentUser.name;
					tmpUsers[index].email = currentUser.email;
					setUsers(tmpUsers);
				}
			})
			.catch((err) => {
				console.error(err);
			});

		setCurrentId(-1);
	};

	const handleCancel = () => {
		setCurrentId(-1);
	};

	const filteredUsers =
		users && users.length > 0
			? users.filter(
					(user) =>
						user.name
							.toLowerCase()
							.includes(searchValue.name.toLowerCase()) &&
						user.email
							.toLowerCase()
							.includes(searchValue.email.toLowerCase())
			  )
			: new Array();

	let pageCount = 0;
	if (filteredUsers.length > 0) {
		pageCount = parseInt(filteredUsers.length / 20) + 1;
	}

	const handleCheckAll = () => {
		setAllCheck(!allCheck);
		setDelDisable(allCheck);

		let checkArray = check.slice(0);
		for (
			let i = (page - 1) * 20;
			i < Math.min(page * 20, users.length);
			i++
		) {
			checkArray[i] = !allCheck;
		}

		setCheck(checkArray);
	};

	const handleCheckChange = (userId) => {
		let index = users.findIndex((user) => user.id === userId);
		let checkArray = check.slice(0);

		checkArray[index] = !checkArray[index];

		let allChecked = true;
		let delDisable = true;

		for (
			let i = (page - 1) * 20;
			i < Math.min(page * 20, users.length);
			i++
		) {
			if (!checkArray[i]) {
				allChecked = false;
			} else {
				delDisable = false;
			}
		}

		const isChecked = checkArray.find((val) => val === true);

		delDisable = !isChecked;

		setCheck(checkArray);
		setAllCheck(allChecked);
		setDelDisable(delDisable);
		// console.log('index', index);
		// console.log('index value', checkArray[index]);
		// console.log('check array', checkArray);
	};

	const handleChangePage = (index) => {
		setPage(index);
	};

	const handlePrev = () => {
		if (page == 1) return;

		setPage(page - 1);
	};

	const handleNext = () => {
		if (page == pageCount) return;

		setPage(page + 1);
	};

	const handlePage = (index) => {
		setPage(index);
	};

	if (!users || !check) {
		return (
			<Container
				fluid
				className='vh-50 d-flex justify-content-center align-items-center'>
				<Spinner color='primary' size='lg' className='mr-2' />
			</Container>
		);
	}

	if (users.length == 0) {
		return (
			<Container
				fluid
				className='vh-50 d-flex justify-content-center align-items-center'>
				<h1>No User Registered!</h1>
			</Container>
		);
	}

	return (
		<Container fluid className='p-0'>
			<Row className='align-items-center' style={{ padding: '10px 0' }}>
				<h1 className='h3 mb-0 mr-7'>Users</h1>
				<UncontrolledButtonDropdown className='mr-1 mb-1'>
					<DropdownToggle caret size='md' color='secondary'>
						{`All (${users.length})`}
					</DropdownToggle>
					<DropdownMenu>
						<DropdownItem>{`All`}</DropdownItem>
						<DropdownItem>{`Non-Premium`}</DropdownItem>
						<DropdownItem>{`Premium`}</DropdownItem>
					</DropdownMenu>
				</UncontrolledButtonDropdown>
			</Row>

			<Row>
				<Col xl='12'>
					<Card>
						<CardHeader>
							<CardTitle tag='h5' className='mb-0'>
								<Button
									color='danger'
									disabled={delDisable}
									onClick={handleDelete}>
									Delete
								</Button>
							</CardTitle>
						</CardHeader>
						<CardBody>
							<Table className='mb-0'>
								<thead>
									<tr>
										<th
											className='text-center'
											style={{ width: 45 }}>
											No
										</th>
										<th
											className='text-center'
											style={{ width: 30 }}>
											<Label check>
												<Input
													type='checkbox'
													style={{
														margin: '-10px 0 0 -10px',
													}}
													checked={allCheck}
													onChange={() =>
														handleCheckAll()
													}
												/>
											</Label>
										</th>
										<th
											className='text-center'
											style={{ width: '5%' }}>
											Image
										</th>
										<th style={{ width: '20%' }}>
											UserName
										</th>
										<th style={{ width: '25%' }}>Email</th>
										<th style={{ width: '10%' }}>
											Registration
										</th>
										<th style={{ width: '10%' }}>
											Login By
										</th>
										<th style={{ width: '10%' }}>
											Confirmation
										</th>
										<th style={{ width: '5%' }}>Status</th>
										<th
											className='text-center'
											style={{ width: 50 }}>
											Edit
										</th>
									</tr>
								</thead>
								<tbody>
									<tr>
										<td></td>
										<td></td>
										<td></td>
										<td>
											<Input
												name='Search-Name'
												placeholder='Search...'
												onChange={(val) => {
													setSearchValue({
														...searchValue,
														name: val.target.value,
													});
													setCurrentId(-1);
													setPage(1);
												}}
												value={searchValue.name}
											/>
										</td>
										<td>
											<Input
												name='Search-Email'
												placeholder='Search...'
												onChange={(val) => {
													setSearchValue({
														...searchValue,
														email: val.target.value,
													});
													setCurrentId(-1);
													setPage(1);
												}}
												value={searchValue.email}
											/>
										</td>
										<td></td>
										<td></td>
										<td></td>
										<td></td>
										<td></td>
									</tr>

									{filteredUsers
										.slice(
											(page - 1) * 20,
											Math.min(page * 20)
										)
										.map((user, index) => {
											let userIndex = users.findIndex(
												(rawUser) =>
													rawUser.id === user.id
											);
											let photo;
											if (user.profile) {
												if (user.profile.photo) {
													photo =
														config.UPLOAD_URL +
														user.profile.photo;
												} else {
													if (
														user.profile.gender == 2
													) {
														photo = female_avatar;
													} else {
														photo = male_avatar;
													}
												}
											} else {
												photo = male_avatar;
											}

											const date = new Date(
												user.created_at
											);
											const year =
												new Intl.DateTimeFormat('en', {
													year: '2-digit',
												}).format(date);
											const month =
												new Intl.DateTimeFormat('en', {
													month: '2-digit',
												}).format(date);
											const day = new Intl.DateTimeFormat(
												'en',
												{ day: '2-digit' }
											).format(date);

											if (index == currentId) {
												return (
													<tr key={index}>
														<td className='text-center'>
															{user.id}
														</td>
														<th className='text-center'>
															<Label check>
																<Input
																	type='checkbox'
																	style={{
																		margin: '-10px 0 0 -10px',
																	}}
																/>
															</Label>
														</th>
														<td className='text-center'>
															<img
																src={photo}
																width='32'
																height='32'
																className='rounded-circle my-n1'
																alt='Avatar'
															/>
														</td>
														<td>
															<Input
																name='Name'
																onChange={(
																	val
																) =>
																	setCurrentUser(
																		{
																			...currentUser,
																			name: val
																				.target
																				.value,
																		}
																	)
																}
																value={
																	currentUser.name
																}
															/>
														</td>
														<td>
															<Input
																name='Email'
																value={
																	currentUser.email
																}
																onChange={(
																	val
																) =>
																	setCurrentUser(
																		{
																			...currentUser,
																			email: val
																				.target
																				.value,
																		}
																	)
																}
															/>
														</td>
														<td>
															<TableTextCell>
																{`${day}-${month}-${year}`}
															</TableTextCell>
														</td>
														<td>
															<TableTextCell>
																{user.type}
															</TableTextCell>
														</td>
														<td>
															<TableTextCell>
																{user.email_verified_at
																	? 'Confirmed'
																	: 'Pending'}
															</TableTextCell>
														</td>
														<td>
															<Badge color='success'>
																Active
															</Badge>
														</td>
														<td className='table-action text-center'>
															<Row
																className='d-flex justify-content-around'
																style={{
																	margin: 'auto',
																	width: 60,
																}}>
																<Button
																	className='p-0'
																	color='none'
																	onClick={() =>
																		handleSave(
																			user.id
																		)
																	}>
																	<Save
																		className='align-middle'
																		size={
																			18
																		}
																	/>
																</Button>
																<Button
																	className='p-0'
																	color='none'
																	onClick={() =>
																		handleCancel()
																	}>
																	<X
																		className='align-middle'
																		size={
																			18
																		}
																	/>
																</Button>
															</Row>
														</td>
													</tr>
												);
											} else {
												return (
													<tr key={index}>
														<td className='text-center'>
															{user.id}
														</td>
														<td className='text-center'>
															<Label check>
																<Input
																	type='checkbox'
																	style={{
																		margin: '-10px 0 0 -10px',
																	}}
																	checked={
																		check[
																			userIndex
																		]
																	}
																	onChange={() =>
																		handleCheckChange(
																			user.id
																		)
																	}
																/>
															</Label>
														</td>
														<td className='text-center'>
															<img
																src={photo}
																width='32'
																height='32'
																className='rounded-circle my-n1'
																alt='Avatar'
															/>
														</td>
														<td>
															<TableTextCell>
																{user.name}
															</TableTextCell>
														</td>
														<td>
															<TableTextCell>
																{user.email}
															</TableTextCell>
														</td>
														<td>
															<TableTextCell>
																{`${day}-${month}-${year}`}
															</TableTextCell>
														</td>
														<td>
															<TableTextCell>
																{user.type}
															</TableTextCell>
														</td>
														<td>
															<TableTextCell>
																{user.email_verified_at
																	? 'Confirmed'
																	: 'Pending'}
															</TableTextCell>
														</td>
														<td>
															<Badge color='success'>
																Active
															</Badge>
														</td>
														<td className='table-action text-center'>
															<Row
																className='d-flex justify-content-around'
																style={{
																	margin: 'auto',
																	width: 60,
																}}>
																<Button
																	className='p-0'
																	color='none'
																	onClick={() =>
																		handleEdit(
																			user.id,
																			index
																		)
																	}>
																	<Edit
																		className='align-middle'
																		size={
																			18
																		}
																	/>
																</Button>
															</Row>
														</td>
													</tr>
												);
											}
										})}
								</tbody>
							</Table>

							<Row className='justify-content-center'>
								<CustomPagination
									pageCount={pageCount}
									page={page}
									onChangePage={(page) =>
										handleChangePage(page)
									}
								/>
							</Row>
							{/* comment */}
							{/* <Row className='justify-content-center'>
								<Pagination
									aria-label='Page navigation'
									size='lg'>
									<PaginationItem>
										<PaginationLink
											previous
											onClick={() => handlePrev()}
										/>
									</PaginationItem>
									{pageCount > 0
										? [...new Array(pageCount)].map(
												(i, index) => (
													<PaginationItem
														active={
															index + 1 == page
																? true
																: false
														}
														key={index}>
														<PaginationLink
															onClick={() =>
																handlePage(
																	index + 1
																)
															}>
															{index + 1}
														</PaginationLink>
													</PaginationItem>
												)
										  )
										: null}
									<PaginationItem>
										<PaginationLink
											next
											onClick={() => handleNext()}
										/>
									</PaginationItem>
								</Pagination>
							</Row> */}
						</CardBody>
					</Card>
				</Col>
			</Row>
		</Container>
	);
};

export default Users;
