import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import {
	Button,
	Col,
	Container,
	Row,
	Input,
	Spinner,
	FormGroup,
	Label,
} from 'reactstrap';
import Select from 'react-select';
import { useHistory } from 'react-router-dom';

import { ApiService } from '../../../services/apiService';
import ImageUploader from '../../../components/ImageUploader';
import { showToastr } from '../../../services/themeService';

const providerOptions = [
	{ value: 1, label: 'AZURE' },
	{ value: 2, label: 'POLLY' },
	{ value: 3, label: 'ACAPELA' },
];

const CharacterEdit = (props) => {
	// var AWS = require('aws-sdk');

	// AWS.config.region = 'eu-north-1'; // Region
	// AWS.config.credentials = new AWS.CognitoIdentityCredentials({
	//   IdentityPoolId: 'eu-north-1:7b8927ac-4c8b-465d-857d-70d11d40a761',
	// });

	// const speakText = () => {
	//   var speechParams = {
	//     OutputFormat: "mp3",
	//     SampleRate: "16000",
	//     Text: "React Hook useEffect has missing dependencies",
	//     TextType: "text",
	//     VoiceId: "Matthew"
	//   };

	//   var polly = new AWS.Polly({ apiVersion: '2016-06-10' });
	//   var signer = new AWS.Polly.Presigner(speechParams, polly);

	//   signer.getSynthesizeSpeechUrl(speechParams, function (error, url) {
	//     if (error) {
	//       console.log(error);
	//     } else {
	//       console.log(url);
	//     }
	//   });
	// }

	const history = useHistory();
	const { session } = useSelector((state) => state.session);

	const page = props.location.state ? props.location.state.page : 1;
	const lang = props.location.state ? props.location.state.language : '';

	const { id } = props.match.params;

	const [character, setCharacter] = useState(null);
	const [voices, setVoices] = useState([]);
	const [languageOptions, setLanguageOptions] = useState([]);
	const [langTypeOptions, setLangTypeOptions] = useState([]);
	const [voiceOptions, setVoiceOptions] = useState([]);
	const [language, setLanguage] = useState(null);
	const [langType, setLangType] = useState(null);
	const [voice, setVoice] = useState(null);
	const [provider, setProvider] = useState(1);
	const [open, setOpen] = useState(false);

	useEffect(() => {
		ApiService.getCharacter(session, id)
			.then((response) => response.json())
			.then((data) => {
				if (data && data.message === 'Unauthenticated.') {
					history.push('/admin');

					return;
				}

				console.log(data);

				const { character, languages, voices } = data;
				if (id && !character) {
					setProvider(null);

					return;
				}

				setVoices(voices);

				let languageOptions = [];
				languages.map((language) => {
					languageOptions.push({
						value: language.code,
						label: language.title,
					});
				});

				setLanguageOptions(languageOptions);
				const languageOption =
					lang && character
						? languageOptions.find(
								(languageOption) =>
									languageOption.value === lang
						  )
						: languageOptions[0];

				setLanguage(languageOption);

				let langTypeOptions = [];
				let voiceOptions = [];

				if (!character) {
					setCharacter({
						name: '',
						isEnemy: 0,
						voice_en: 1,
						voice_es: 5,
						voice_fr: 13,
						voice_de: 21,
						image: '',
						trl_en: '',
						trl_es: '',
						trl_fr: '',
						trl_de: '',
					});

					setProvider(providerOptions[0]);

					voices
						.filter(
							(voice) =>
								voice.language.substr(0, 2) ===
									languageOption.value &&
								voice.provider === providerOptions[0].value
						)
						.map((voice) => {
							if (
								langTypeOptions.find(
									(langTypeOption) =>
										langTypeOption.label === voice.language
								) === undefined
							) {
								langTypeOptions.push({
									value: voice.language,
									label: voice.language,
								});
							}
						});

					setLangTypeOptions(langTypeOptions);

					setLangType(langTypeOptions[0]);

					voices
						.filter(
							(voice) =>
								voice.language === langTypeOptions[0].label &&
								voice.provider === providerOptions[0].value
						)
						.map((voice) => {
							voiceOptions.push({
								value: voice.id,
								label: voice.voice,
							});
						});

					setVoiceOptions(voiceOptions);

					setVoice(voiceOptions[0]);

					return;
				}

				console.log('get characters', character);
				!character.isEnemy
					? (character.isEnemy = 0)
					: (character.isEnemy = 1);
				setCharacter(character);

				const provider = voices.find(
					(voice) =>
						voice.id === character['voice_' + languageOption.value]
				).provider;
				setProvider(
					providerOptions.find(
						(providerOption) => providerOption.value === provider
					)
				);

				voices
					.filter(
						(voice) =>
							voice.language.substr(0, 2) ===
								languageOption.value &&
							voice.provider === provider
					)
					.map((voice) => {
						if (
							langTypeOptions.find(
								(langTypeOption) =>
									langTypeOption.label === voice.language
							) === undefined
						) {
							langTypeOptions.push({
								value: voice.language,
								label: voice.language,
							});
						}
					});

				setLangTypeOptions(langTypeOptions);

				const langType = voices.find(
					(voice) =>
						voice.id === character['voice_' + languageOption.value]
				).language;
				setLangType(
					langTypeOptions.find(
						(langTypeOption) => langTypeOption.label === langType
					)
				);

				voices
					.filter(
						(voice) =>
							voice.language === langType &&
							voice.provider === provider
					)
					.map((voice) => {
						if (
							voiceOptions.find(
								(voiceOption) => voiceOption.value === voice.id
							) === undefined
						) {
							voiceOptions.push({
								value: voice.id,
								label: voice.voice,
							});
						}
					});

				setVoiceOptions(voiceOptions);

				const voice = voices.find(
					(voice) =>
						voice.id === character['voice_' + languageOption.value]
				).id;
				setVoice(
					voiceOptions.find(
						(voiceOption) => voiceOption.value === voice
					)
				);
			})
			.catch((err) => {
				console.error(err);
			});
	}, []);

	const toggle = () => {
		setOpen(!open);
	};

	const updateExtraFields = (fieldToUpdate) => {
		console.log('field to update', fieldToUpdate);
		setCharacter({ ...character, ...fieldToUpdate });
	};

	const handleSave = () => {
		if (!character.name) {
			showToastr('warning', 'Fields Required', 'Please input Name/ID.');

			return;
		}

		// update titles here
		character.title_en = character.title_en;
		character.title_es = character.title_es;
		character.title_fr = character.title_fr;
		character.title_de = character.title_de;

		// update description here
		character.desc_en = character.desc_en;
		character.desc_es = character.desc_es;
		character.desc_fr = character.desc_fr;
		character.desc_de = character.desc_de;

		ApiService.updateCharacter(session, id, character)
			.then((response) => {
				history.push('/admin/settings/characters', {
					page: page ? page : '',
					language: lang ? lang : '',
				});
			})
			.catch((err) => {
				console.error(err);
			});

		toggle();
	};

	const handleLanguageChange = (item) => {
		setLanguage(item);

		let langTypeOptions = [];
		let voiceOptions = [];

		if (id) {
			const provider = voices.find(
				(voice) => voice.id === character['voice_' + item.value]
			).provider;
			setProvider(
				providerOptions.find(
					(providerOption) => providerOption.value === provider
				)
			);

			voices
				.filter(
					(voice) =>
						voice.language.substr(0, 2) === item.value &&
						voice.provider === provider
				)
				.map((voice) => {
					if (
						langTypeOptions.find(
							(langTypeOption) =>
								langTypeOption.label === voice.language
						) === undefined
					) {
						langTypeOptions.push({
							value: voice.language,
							label: voice.language,
						});
					}
				});

			setLangTypeOptions(langTypeOptions);

			const langType = voices.find(
				(voice) => voice.id === character['voice_' + item.value]
			).language;
			setLangType(
				langTypeOptions.find(
					(langTypeOption) => langTypeOption.label === langType
				)
			);

			voices
				.filter(
					(voice) =>
						voice.language === langType &&
						voice.provider === provider
				)
				.map((voice) => {
					if (
						voiceOptions.find(
							(voiceOption) => voiceOption.value === voice.id
						) === undefined
					) {
						voiceOptions.push({
							value: voice.id,
							label: voice.voice,
						});
					}
				});

			setVoiceOptions(voiceOptions);

			const voice = voices.find(
				(voice) => voice.id === character['voice_' + item.value]
			).id;
			setVoice(
				voiceOptions.find((voiceOption) => voiceOption.value === voice)
			);
		} else {
			setProvider(providerOptions[0]);

			voices
				.filter(
					(voice) =>
						voice.language.substr(0, 2) === item.value &&
						voice.provider === providerOptions[0].value
				)
				.map((voice) => {
					if (
						langTypeOptions.find(
							(langTypeOption) =>
								langTypeOption.label === voice.language
						) === undefined
					) {
						langTypeOptions.push({
							value: voice.language,
							label: voice.language,
						});
					}
				});

			setLangTypeOptions(langTypeOptions);

			setLangType(langTypeOptions[0]);

			voices
				.filter(
					(voice) =>
						voice.language === langTypeOptions[0].label &&
						voice.provider === providerOptions[0].value
				)
				.map((voice) => {
					if (
						voiceOptions.find(
							(voiceOption) => voiceOption.value === voice.id
						) === undefined
					) {
						voiceOptions.push({
							value: voice.id,
							label: voice.voice,
						});
					}
				});

			setVoiceOptions(voiceOptions);

			setVoice(voiceOptions[0]);

			setCharacter({
				...character,
				['voice_' + item.value]: voiceOptions[0].value,
			});
		}
	};

	const handleProviderChange = (item) => {
		setProvider(item);

		let langTypeOptions = [];
		let voiceOptions = [];

		voices
			.filter(
				(voice) =>
					voice.language.substr(0, 2) === language.value &&
					voice.provider === item.value
			)
			.map((voice) => {
				if (
					langTypeOptions.find(
						(langTypeOption) =>
							langTypeOption.label === voice.language
					) === undefined
				) {
					langTypeOptions.push({
						value: voice.language,
						label: voice.language,
					});
				}
			});

		setLangTypeOptions(langTypeOptions);

		setLangType(langTypeOptions[0]);

		voices
			.filter(
				(voice) =>
					voice.language === langTypeOptions[0].label &&
					voice.provider === item.value
			)
			.map((voice) => {
				if (
					voiceOptions.find(
						(voiceOption) => voiceOption.value === voice.id
					) === undefined
				) {
					voiceOptions.push({
						value: voice.id,
						label: voice.voice,
					});
				}
			});

		setVoiceOptions(voiceOptions);

		setVoice(voiceOptions[0]);

		setCharacter({
			...character,
			['voice_' + language.value]: voiceOptions[0].value,
		});
	};

	const handleLangTypeChange = (item) => {
		setLangType(item);

		let voiceOptions = [];

		voices
			.filter(
				(voice) =>
					voice.language === item.label &&
					voice.provider === provider.value
			)
			.map((voice) => {
				if (
					voiceOptions.find(
						(voiceOption) => voiceOption.value === voice.id
					) === undefined
				) {
					voiceOptions.push({
						value: voice.id,
						label: voice.voice,
					});
				}
			});

		setVoiceOptions(voiceOptions);

		setVoice(voiceOptions[0]);

		setCharacter({
			...character,
			['voice_' + language.value]: voiceOptions[0].value,
		});
	};

	if (!character && provider) {
		return (
			<Container
				fluid
				className='vh-50 d-flex justify-content-center align-items-center'>
				<Spinner color='primary' size='lg' className='mr-2' />
			</Container>
		);
	}

	if (id && !character && !provider) {
		return (
			<Container
				fluid
				className='vh-50 d-flex justify-content-center align-items-center'>
				<h1>No Level Found!</h1>
			</Container>
		);
	}

	return (
		<Container fluid className='p-0'>
			<h1 className='h3 mb-3'>Character</h1>

			<Row>
				<Col>
					<h5 className='mt-3'>Image</h5>
					<ImageUploader
						img={character.image + '?' + new Date().getTime()}
						onChange={(img) =>
							setCharacter({ ...character, image: img })
						}
					/>
					<h5 className='mt-3'>Name/ID</h5>
					<Input
						name='Name'
						onChange={(val) => {
							setCharacter({
								...character,
								name: val.target.value,
							});
						}}
						value={character.name}
					/>
					<h5 className='mt-3'>Languages</h5>
					<Select
						className='react-select-container'
						classNamePrefix='react-select'
						options={languageOptions}
						isSearchable={false}
						onChange={(item) => handleLanguageChange(item)}
						value={language}
					/>
					<h5 className='mt-3'>Providers</h5>
					<Select
						className='react-select-container'
						classNamePrefix='react-select'
						options={providerOptions}
						isSearchable={false}
						onChange={(item) => handleProviderChange(item)}
						value={provider}
					/>
					<h5 className='mt-3'>Language Types</h5>
					<Select
						className='react-select-container'
						classNamePrefix='react-select'
						options={langTypeOptions}
						isSearchable={false}
						onChange={(item) => handleLangTypeChange(item)}
						value={langType}
					/>
					<h5 className='mt-3'>Voices</h5>
					<Select
						className='react-select-container'
						classNamePrefix='react-select'
						options={voiceOptions}
						isSearchable={false}
						onChange={(item) => {
							setVoice(item);
							setCharacter({
								...character,
								['voice_' + language.value]: item.value,
							});
						}}
						value={voice}
					/>
					<h5 className='mt-3'>Translation</h5>
					<Input
						name='Translation'
						onChange={(val) => {
							setCharacter({
								...character,
								['trl_' + language.value]: val.target.value,
							});
						}}
						value={
							character['trl_' + language.value]
								? character['trl_' + language.value]
								: ''
						}
					/>

					<h5 className='mt-3'>Enemy</h5>
					<div className='d-flex'>
						<FormGroup check className='mr-4'>
							<Label check>
								<Input
									type='radio'
									name='enemy'
									onChange={() =>
										updateExtraFields({ isEnemy: 0 })
									}
									checked={!character.isEnemy && true}
								/>
								No
							</Label>
						</FormGroup>
						<FormGroup check>
							<Label check>
								<Input
									type='radio'
									name='enemy'
									onChange={() =>
										updateExtraFields({ isEnemy: 1 })
									}
									checked={character.isEnemy && true}
								/>
								Yes
							</Label>
						</FormGroup>
					</div>

					{character.isEnemy === 1 && (
						<div>
							{/**  Title Inputs*/}
							<div>
								<h5 className='mt-3'>Title</h5>
								<div className='mt-3 d-flex justify-content-between'>
									<div style={{ width: '24%' }}>
										<p
											style={{
												fontSize: 12,
												marginBottom: 5,
											}}>
											English
										</p>
										<Input
											name='Title-en'
											value={character.title_en}
											onChange={(e) =>
												updateExtraFields({
													title_en: e.target.value,
												})
											}
										/>
									</div>
									<div style={{ width: '24%' }}>
										<p
											style={{
												fontSize: 12,
												marginBottom: 5,
											}}>
											Spanish
										</p>
										<Input
											name='Title-es'
											value={character.title_es}
											onChange={(e) =>
												updateExtraFields({
													title_es: e.target.value,
												})
											}
										/>
									</div>
									<div style={{ width: '24%' }}>
										<p
											style={{
												fontSize: 12,
												marginBottom: 5,
											}}>
											French
										</p>
										<Input
											name='Title-fr'
											value={character.title_fr}
											onChange={(e) =>
												updateExtraFields({
													title_fr: e.target.value,
												})
											}
										/>
									</div>
									<div style={{ width: '24%' }}>
										<p
											style={{
												fontSize: 12,
												marginBottom: 5,
											}}>
											German
										</p>
										<Input
											name='Title-de'
											value={character.title_de}
											onChange={(e) =>
												updateExtraFields({
													title_de: e.target.value,
												})
											}
										/>
									</div>
								</div>
							</div>

							{/* Description Inputs */}
							<div>
								<h5 className='mt-3'>Description</h5>
								<div className='mt-3 d-flex justify-content-between'>
									<div style={{ width: '24%' }}>
										<p
											style={{
												fontSize: 12,
												marginBottom: 5,
											}}>
											English
										</p>
										<Input
											name='Title-en'
											value={character.desc_en}
											onChange={(e) =>
												updateExtraFields({
													desc_en: e.target.value,
												})
											}
										/>
									</div>
									<div style={{ width: '24%' }}>
										<p
											style={{
												fontSize: 12,
												marginBottom: 5,
											}}>
											Spanish
										</p>
										<Input
											name='Title-es'
											value={character.desc_es}
											onChange={(e) =>
												updateExtraFields({
													desc_es: e.target.value,
												})
											}
										/>
									</div>
									<div style={{ width: '24%' }}>
										<p
											style={{
												fontSize: 12,
												marginBottom: 5,
											}}>
											French
										</p>
										<Input
											name='Title-fr'
											value={character.desc_fr}
											onChange={(e) =>
												updateExtraFields({
													desc_fr: e.target.value,
												})
											}
										/>
									</div>
									<div style={{ width: '24%' }}>
										<p
											style={{
												fontSize: 12,
												marginBottom: 5,
											}}>
											German
										</p>
										<Input
											name='Title-de'
											value={character.desc_de}
											onChange={(e) =>
												updateExtraFields({
													desc_de: e.target.value,
												})
											}
										/>
									</div>
								</div>
							</div>
						</div>
					)}
					<Row className='mt-7 justify-content-center'>
						<Button
							color='secondary'
							className='mr-3'
							onClick={() =>
								history.push('/admin/settings/characters', {
									page: page ? page : '',
									language: lang ? lang : '',
								})
							}>
							Cancel
						</Button>
						<Button color='primary' onClick={() => handleSave()}>
							Save
						</Button>
					</Row>
				</Col>
			</Row>
		</Container>
	);
};

export default CharacterEdit;
