import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import {
  Button,
  Col,
  Container,
  Row,
  Input,
  Spinner,
} from "reactstrap";
import Select from "react-select";
import { useHistory } from "react-router-dom";

import { ApiService } from '../../services/apiService';
import ImageUploader from '../../components/ImageUploader';
import { showToastr } from '../../services/themeService';


const statusOptions = [
  { value: 1, label: "DeActive" },
  { value: 2, label: "Active" },
];


const LanguageEdit = (props) => {

  const history = useHistory();
  const {session} = useSelector((state) => state.session);

  const { id } = props.match.params;

  const [language, setLanguage] = useState(null);
  const [open, setOpen] = useState(false);
  const [status, setStatus] = useState(statusOptions[1]);

  useEffect(() => {
    ApiService.getLanguage(session, id)
      .then(response => response.json())
      .then(data => {
        if (data && data.message === "Unauthenticated.") {
          history.push('/admin');

          return;
        }
        
        const language = data;
        if (id && !language) {
          setStatus(null);

          return;
        }

        console.log(language);
        if (Object.keys(language).length === 0) {
          setLanguage({
            title: '',
            code: '',
            image: '',
          });

          return;
        }

        setLanguage({
          title: language.title,
          code: language.code,
          image: language.image,
        });

        setStatus(statusOptions.filter(option =>
          option.value == language.status
        )[0]);
      })
      .catch((err) => {
        console.error(err);
      });
  }, []);

  const toggle = () => {
    setOpen(!open);
  }

  const handleSave = () => {
    if (!language.title || !language.code) {
      showToastr('warning', 'Fields Required', 'Please input fields.');

      return;
    }
    console.log(id);
    console.log(language);
    console.log(status.value);
    ApiService.updateLanguage(session, id, language, status.value)
      .then((response) => {
        history.push('/admin/languages');
      })
      .catch((err) => {
        console.error(err);
      });

    toggle();
  }

  if (!language && status) {
    return (
      <Container fluid className="vh-50 d-flex justify-content-center align-items-center">
        <Spinner color="primary" size="lg" className="mr-2" />
      </Container>
    );
  }

  if (id && !language && !status) {
    return (
      <Container fluid className="vh-50 d-flex justify-content-center align-items-center">
        <h1>No Language Found!</h1>
      </Container>
    );
  }

  return (
    <Container fluid className="p-0">
      <h1 className="h3 mb-3">Language</h1>

      <Row>
        <Col>
          <ImageUploader round img={language.image + '?' + new Date().getTime()} onChange={(img) => setLanguage({ ...language, image: img })} />
          <h5 className="mt-3">Title</h5>
          <Input
            name="Title"
            onChange={(val) => { setLanguage({ ...language, title: val.target.value }); }}
            value={language.title}
          />
          <h5 className="mt-3">Code</h5>
          <Input
            name="Code"
            onChange={(val) => { setLanguage({ ...language, code: val.target.value }); }}
            value={language.code}
          />
          <h5 className="mt-3">Status</h5>
          <Select
            className="react-select-container"
            classNamePrefix="react-select"
            options={statusOptions}
            isSearchable={false}
            onChange={(item) => setStatus(item)}
            value={status}
          />
          <Row className="mt-7 justify-content-center">
            <Button color="secondary" className="mr-3" onClick={() => history.push('/admin/languages')}>Cancel</Button>
            <Button color="primary" onClick={() => handleSave()}>Save</Button>
          </Row>
        </Col>
      </Row>
    </Container >
  );
}

export default LanguageEdit;
