import React, { useState } from 'react';
import reactCSS from 'reactcss';
import { SketchPicker } from 'react-color';


const ColorPicker = (props) => {

  const { onChange, color } = props;

  const [visible, setVisible] = useState(false);

  const styles = reactCSS({
    'default': {
      color: {
        width: '36px',
        height: '14px',
        borderRadius: '2px',
        background: `${color}`,
      },
      swatch: {
        padding: '5px',
        background: '#fff',
        borderRadius: '1px',
        boxShadow: '0 0 0 1px rgba(0,0,0,.1)',
        display: 'inline-block',
        cursor: 'pointer',
      },
      popover: {
        position: 'absolute',
        zIndex: '2',
        left: 65,
        top: 166,
      },
      cover: {
        position: 'fixed',
        top: '0px',
        right: '0px',
        bottom: '0px',
        left: '0px',
      },
    },
  });


  return (
    <div>
      <div style={styles.swatch} onClick={() => setVisible(!visible)}>
        <div style={styles.color} />
      </div>
      {
        visible ? (
          <div style={styles.popover}>
            <div style={styles.cover} onClick={() => setVisible(false)} />
            <SketchPicker
              color={color}
              onChange={onChange}
            />
          </div>
        ) : null
      }

    </div>
  );
}

export default ColorPicker