import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import {
  Button,
  Card,
  CardBody,
  Col,
  Container,
  Row,
  Input,
  Table,
  Spinner,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from "reactstrap";
import Select from "react-select";
import { useHistory } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlusCircle } from "@fortawesome/free-solid-svg-icons";
import { Edit, Trash2, } from "react-feather";

import TableTextCell from '../../components/TableTextCell';
import { ApiService } from '../../services/apiService';
import { config } from "../../config";

const statusOptions = [
  { value: 0, label: "All" },
  { value: 1, label: "DeActive" },
  { value: 2, label: "Active" },
];


const Languages = (props) => {

  const history = useHistory();
  const { session } = useSelector((state) => state.session);

  useEffect(() => {
    ApiService.getLanguages(session)
      .then(response => response.json())
      .then(data => {
        if (data && data.message === "Unauthenticated.") {
          history.push('/admin');

          return;
        }

        console.log(data);
        setLanguages(data);
      })
      .catch((err) => {
        console.error(err);
      });
  }, []);

  const [languages, setLanguages] = useState(null);
  const [currentLanguage, setCurrentLanguage] = useState({});
  const [searchValue, setSearchValue] = useState({ title: '', code: '', status: 0 });

  const [open, setOpen] = useState(false);

  const toggle = () => {
    setOpen(!open);
  }

  const handleEdit = (id) => {
    history.push(`/admin/language/${id}`);
  }

  const handleDelete = (id) => {
    console.log(id);

    const curLanguage = languages.filter(language => language.id == id);

    if (curLanguage.length > 0)
      setCurrentLanguage(curLanguage[0]);

    toggle();
  }

  const handleConfirm = () => {
    console.log(currentLanguage.id);
    ApiService.deleteLanguage(session, currentLanguage.id)
      .then((data) => {
        console.log(data);
        let tmpLanguages = languages.slice();
        const curLanguage = languages.filter(language => language.id == currentLanguage.id);
        if (curLanguage.length > 0) {
          const index = tmpLanguages.indexOf(curLanguage[0]);
          console.log(curLanguage[0]);
          console.log(index);
          if (index > -1) {
            tmpLanguages.splice(index, 1);
          }
        }
        console.log('Delete');
        console.log(tmpLanguages);

        setLanguages(tmpLanguages);
      })
      .catch((err) => {
        console.error(err);
      });

    toggle();
  }

  const filteredLanguages = languages && languages.length > 0 ?
    languages.filter(language =>
      language.title.toLowerCase().includes(searchValue.title.toLowerCase()) &&
      (searchValue.status == 0 ? true : language.status == searchValue.status)
    ) :
    new Array();

  let pageCount = 0;
  if (filteredLanguages.length > 0) {
    pageCount = parseInt(filteredLanguages.length / 20) + 1;
  }


  if (!languages) {
    return (
      <Container fluid className="vh-50 d-flex justify-content-center align-items-center">
        <Spinner color="primary" size="lg" className="mr-2" />
      </Container>
    );
  }

  return (
    <Container fluid className="p-0">
      <h1 className="h3 mb-3">Platform Languages</h1>
      <Button color="primary" className="btn-pill mr-1 mb-3" outline onClick={() => history.push('/admin/language')}>
        <FontAwesomeIcon icon={faPlusCircle} color="primary" className="mr-2" />
        Add New Language
      </Button>

      <Row>
        <Col>
          <Card>
            {/* <CardHeader>
              <CardTitle tag="h5" className="mb-0">Empty card</CardTitle>
            </CardHeader> */}
            <CardBody>
              <Table bordered style={{ tableLayout: 'fixed' }}>
                <thead>
                  <tr>
                    <th className="text-center" style={{ width: 45 }}>No</th>
                    <th className="text-center" style={{ width: 70 }}>Flag</th>
                    <th className="text-center">Title</th>
                    <th className="text-center">Code</th>
                    <th className="text-center">Status</th>
                    <th className="text-center" style={{ width: 85 }}>Actions</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td></td>
                    <td></td>
                    <td>
                      <Input
                        name="Search-Title"
                        placeholder="Search..."
                        onChange={(val) => { setSearchValue({ ...searchValue, title: val.target.value }); }}
                        value={searchValue.title}
                      />
                    </td>
                    <td></td>
                    <td>
                      <Select
                        className="react-select-container"
                        classNamePrefix="react-select"
                        options={statusOptions}
                        isSearchable={false}
                        defaultValue={statusOptions[0]}
                        onChange={(item) => setSearchValue({ ...searchValue, status: item.value })}
                      />
                    </td>
                    <td></td>
                  </tr>

                  {
                    filteredLanguages.map((language, index) => (
                      <tr key={index}>
                        <td className="text-center">{language.id}</td>
                        <td className="text-center">
                          {language.image ? (
                            <img
                              src={config.UPLOAD_URL + language.image + '?' + new Date().getTime()}
                              width="32"
                              height="32"
                              className="rounded-circle my-n1"
                              alt="Flag"
                            />
                          )
                            : null}
                        </td>
                        <td>
                          <TableTextCell>
                            {language.title}
                          </TableTextCell>
                        </td>
                        <td>
                          <TableTextCell>
                            {language.code}
                          </TableTextCell>
                        </td>
                        <td>
                          <TableTextCell>
                            {language.status == 2 ? 'Active' : 'DeActive'}
                          </TableTextCell>
                        </td>
                        <td className="table-action text-center">
                          <Row className="d-flex justify-content-around" style={{ margin: 'auto', width: 60 }}>
                            <Button className="p-0" color="none" onClick={() => handleEdit(language.id)}>
                              <Edit className="align-middle" size={18} />
                            </Button>
                            <Button className="p-0" color="none" onClick={() => handleDelete(language.id)}>
                              <Trash2 className="align-middle" size={18} />
                            </Button>
                          </Row>
                        </td>
                      </tr>
                    ))}
                </tbody>
              </Table>

              <Modal
                isOpen={open}
                toggle={() => toggle()}
                centered
              >
                <ModalHeader toggle={() => toggle()}>
                  Delete language
                </ModalHeader>
                <ModalBody className="text-center m-3">
                  <p className="mb-0">
                    Are you sure want to delete this language?
                  </p>
                </ModalBody>
                <ModalFooter>
                  <Button color="secondary" onClick={() => toggle()}>
                    Cancel
                  </Button>{" "}
                  <Button color="danger" onClick={() => handleConfirm()}>
                    Delete
                  </Button>
                </ModalFooter>
              </Modal>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </Container >
  );
}

export default Languages;
