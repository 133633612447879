import React from "react";
import { BrowserRouter as Router, Redirect, Route, Switch } from "react-router-dom";
import {
  home as homeRoutes,
  sidebar as sidebarRoutes,
} from "./index";

import DashboardLayout from "../layouts/Dashboard";
import HomeLayout from "../layouts/Home";
import AuthLayout from "../layouts/Auth";
import Page404 from "../pages/auth/Page404";

import ScrollToTop from "../components/ScrollToTop";

const childRoutes = (Layout, routes) =>
  routes.map(({ children, path, exact, component: Component }, index) =>
    children ? (
      // Route item with children
      children.map(({ path, exact, component: Component }, index) => (
        <Route
          key={index}
          path={path}
          exact={exact}
          render={props => (
            <Layout>
              <Component {...props} />
            </Layout>
          )}
        />
      ))
    ) : (
        // Route item without children
        <Route
          key={index}
          path={path}
          exact={exact}
          render={props => (
            <Layout>
              <Component {...props} />
            </Layout>
          )}
        />
      )
  );

const Routes = () => (
  <Router>
    <ScrollToTop>
      <Switch>
        {childRoutes(HomeLayout, homeRoutes)}
        {childRoutes(DashboardLayout, sidebarRoutes)}
        <Redirect from="/" to="/admin" />
        <Route
          render={() => (
            <AuthLayout>
              <Page404 />
            </AuthLayout>
          )}
        />
      </Switch>
    </ScrollToTop>
  </Router>
);

export default Routes;
