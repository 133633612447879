import React from 'react';

export const ResetPassSuccess = ({ title, message }) => (
	<div style={styles.successDivContainer}>
		<img
			width='100'
			src='https://app.languagejourneys.com:8000/uploads/tick_mark.png'
		/>

		<h4>
			<b>{title}</b>
		</h4>
		<h5>{message}</h5>
	</div>
);

const styles = {
	successDivContainer: {
		display: 'block',
		margin: 'auto',
		textAlign: 'center',
	},
};
