import {
	BookOpen as BookOpenIcon,
	List as ListIcon,
	Sliders as SlidersIcon,
	Users as UsersIcon,
	Settings as SettingsIcon,
} from 'react-feather';

import Home from '../pages/home/Home';
import Users from '../pages/users/Users';

import Dashboard from '../pages/Dashboard';

import Languages from '../pages/languages/Languages';
import LanguageEdit from '../pages/languages/LanguageEdit';

import Levels from '../pages/journeys/Levels/Levels';
import LevelEdit from '../pages/journeys/Levels/LevelEdit';

import Chapters from '../pages/journeys/Chapters/Chapters';
import ChapterEdit from '../pages/journeys/Chapters/ChapterEdit';

import Activities from '../pages/journeys/Activities/Activities';
import ActivityEdit from '../pages/journeys/Activities/ActivityEdit';

import Translations from '../pages/settings/Translations';
import Ads from '../pages/settings/Ads';

import Characters from '../pages/settings/characters/Characters';
import CharacterEdit from '../pages/settings/characters/CharacterEdit';

import ForgotPassword from '../pages/auth/ForgotPassword';
import ConfirmEmail from '../pages/auth/ConfirmEmail';
import ResetPassword from '../pages/auth/ResetPassword';
import Page404 from '../pages/auth/Page404';
import Page500 from '../pages/auth/Page500';

// Routes
const homeRoutes = {
	path: '/admin',
	name: 'Home',
	component: Home,
	children: null,
	exact: true,
	children: [
		{
			path: '/admin',
			name: 'Login',
			exact: true,
			component: Home,
		},

		{
			path: '/admin/auth/reset-password',
			name: 'Forgot Password',
			exact: true,
			component: ForgotPassword,
		},

		{
			path: '/admin/auth/confirm/:token?',
			name: 'Confirm Email',
			exact: false,
			component: ConfirmEmail,
		},

		{
			path: '/admin/pass/reset-password/:token/:lang',
			name: 'Reset Password',
			exact: false,
			component: ResetPassword,
		},
	],
};

const dashboardRoutes = {
	path: '/admin/dashboard',
	name: 'Dashboard',
	badgeColor: 'primary',
	component: Dashboard,
	icon: SlidersIcon,
	containsHome: true,
	exact: true,
};

const userRoutes = {
	path: '/admin/users',
	name: 'Users',
	icon: UsersIcon,
	exact: true,
	component: Users,
};

const languageRoutes = {
	path: '/admin/languages',
	name: 'Platform Languages',
	icon: BookOpenIcon,
	component: Languages,
	exact: true,
	children: null,
};

const languageEditRoute = {
	path: '/admin/language/:id?',
	name: 'LanguageEdit',
	icon: BookOpenIcon,
	component: LanguageEdit,
	exact: false,
	children: null,
};

const journeysRoutes = {
	path: '/admin/journeys',
	name: 'Journeys',
	icon: ListIcon,
	children: [
		{
			path: '/admin/journeys/levels',
			name: 'Levels',
			exact: true,
			component: Levels,
		},
		{
			path: '/admin/journeys/chapters',
			name: 'Chapters',
			exact: true,
			component: Chapters,
		},
		{
			path: '/admin/journeys/activities',
			name: 'Activities',
			exact: true,
			component: Activities,
		},
	],
};

const levelEditRoute = {
	path: '/admin/journeys/level/:id?',
	name: 'LevelEdit',
	component: LevelEdit,
	exact: false,
	children: null,
};

const chapterEditRoute = {
	path: '/admin/journeys/chapter/:id?',
	name: 'ChapterEdit',
	component: ChapterEdit,
	exact: false,
	children: null,
};

const activityEditRoute = {
	path: '/admin/journeys/activity/:id?',
	name: 'ActivityEdit',
	component: ActivityEdit,
	exact: false,
	children: null,
};

const settingRoutes = {
	path: '/admin/settings',
	name: 'Settings',
	icon: SettingsIcon,
	children: [
		{
			path: '/admin/settings/translations',
			name: 'Translations',
			exact: true,
			component: Translations,
		},
		{
			path: '/admin/settings/characters',
			name: 'Characters',
			exact: true,
			component: Characters,
		},
		{
			path: '/admin/settings/ads',
			name: 'Ads',
			exact: true,
			component: Ads,
		},
	],
};

const characterEditRoute = {
	path: '/admin/settings/character/:id?',
	name: 'CharacterEdit',
	component: CharacterEdit,
	exact: false,
	children: null,
};

const authRoutes = {
	path: '/admin/auth',
	name: 'Auth',
	icon: UsersIcon,
	badgeColor: 'secondary',
	badgeText: 'Special',
	children: [
		{
			path: '/admin/auth/404',
			name: '404 Page',
			exact: true,
			component: Page404,
		},
		{
			path: '/admin/auth/500',
			name: '500 Page',
			exact: true,
			component: Page500,
		},
	],
};

// Dashboard specific routes
export const sidebar = [
	dashboardRoutes,
	userRoutes,
	languageRoutes,
	journeysRoutes,
	settingRoutes,
	authRoutes,
	levelEditRoute,
	chapterEditRoute,
	languageEditRoute,
	characterEditRoute,
	activityEditRoute,
];

// Home specific routes
export const home = [homeRoutes];

// All routes
export default [
	dashboardRoutes,
	userRoutes,
	languageRoutes,
	journeysRoutes,
	settingRoutes,
];
