import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import {
  Button,
  Card,
  CardBody,
  Col,
  Container,
  Row,
  Pagination,
  PaginationItem,
  PaginationLink,
  Input,
  Table,
  Spinner,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from "reactstrap";
import Select from "react-select";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlusCircle } from "@fortawesome/free-solid-svg-icons";
import { Edit, Trash2, } from "react-feather";

import TableTextCell from '../../../components/TableTextCell';
import { ApiService } from '../../../services/apiService';
import { useHistory } from "react-router-dom";

const statusOptions = [
  { value: 0, label: "All" },
  { value: 1, label: "Draft" },
  { value: 2, label: "Published" },
];


const Levels = (props) => {

  const history = useHistory();
  const {session} = useSelector((state) => state.session);

  useEffect(() => {
    ApiService.getLevels(session)
      .then(response => response.json())
      .then(data => {
        if (data && data.message === "Unauthenticated.") {
          history.push('/admin');

          return;
        }
        
        setLevels(data);
      })
      .catch((err) => {
        console.error(err);
      });
  }, []);

  const [levels, setLevels] = useState(null);
  const [currentLevel, setCurrentLevel] = useState({});
  const [searchValue, setSearchValue] = useState({ lang: '', level: '', status: 0 });
  const [page, setPage] = useState(props.location.state ? props.location.state : 1);

  const [open, setOpen] = useState(false);

  const toggle = () => {
    setOpen(!open);
  }

  const handleEdit = (id) => {
    history.push(`/admin/journeys/level/${id}`, page);
  }

  const handleDelete = (id) => {
    console.log(id);

    const curLevel = levels.filter(level => level.id == id);

    if (curLevel.length > 0)
      setCurrentLevel(curLevel[0]);

    toggle();
  }

  const handleConfirm = () => {
    console.log(currentLevel.id);
    ApiService.deleteLevel(session, currentLevel.id)
      .then((data) => {
        console.log(data);
        let tmpLevels = levels.slice();
        const curLevel = levels.filter(level => level.id == currentLevel.id);
        if (curLevel.length > 0) {
          const index = tmpLevels.indexOf(curLevel[0]);
          console.log(curLevel[0]);
          console.log(index);
          if (index > -1) {
            tmpLevels.splice(index, 1);
          }
        }
        console.log('Delete');
        console.log(tmpLevels);

        setLevels(tmpLevels);
      })
      .catch((err) => {
        console.error(err);
      });

    toggle();
  }

  const filteredLevels = levels && levels.length > 0 ?
    levels.filter(level =>
      level.language_code.toLowerCase().includes(searchValue.lang.toLowerCase()) &&
      level.level.toLowerCase().includes(searchValue.level.toLowerCase()) &&
      (searchValue.status == 0 ? true : level.status == searchValue.status)
    ) :
    new Array();

  let pageCount = 0;
  if (filteredLevels.length > 0) {
    pageCount = parseInt(filteredLevels.length / 20) + 1;
  }

  const handlePrev = () => {
    if (page == 1)
      return;

    setPage(page - 1);
  }

  const handleNext = () => {
    if (page == pageCount)
      return;

    setPage(page + 1);
  }

  const handlePage = (index) => {
    setPage(index);
  }


  if (!levels) {
    return (
      <Container fluid className="vh-50 d-flex justify-content-center align-items-center">
        <Spinner color="primary" size="lg" className="mr-2" />
      </Container>
    );
  }

  return (
    <Container fluid className="p-0">
      <h1 className="h3 mb-3">Journey Levels</h1>
      <Button color="primary" className="btn-pill mr-1 mb-3" outline onClick={() => history.push('/admin/journeys/level')}>
        <FontAwesomeIcon icon={faPlusCircle} color="primary" className="mr-2" />
        Add New Level
      </Button>

      <Row>
        <Col>
          <Card>
            {/* <CardHeader>
              <CardTitle tag="h5" className="mb-0">Empty card</CardTitle>
            </CardHeader> */}
            <CardBody>
              <Table bordered style={{ tableLayout: 'fixed' }}>
                <thead>
                  <tr>
                    <th className="text-center" style={{ width: 45 }}>No</th>
                    <th className="text-center">Language</th>
                    <th className="text-center">Level</th>
                    <th className="text-center">Status</th>
                    <th className="text-center" style={{ width: 85 }}>Actions</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td></td>
                    <td>
                      <Input
                        name="Search-Lang"
                        placeholder="Search..."
                        onChange={(val) => { setSearchValue({ ...searchValue, lang: val.target.value }); setPage(1); }}
                        value={searchValue.lang}
                      />
                    </td>
                    <td>
                      <Input
                        name="Search-Level"
                        placeholder="Search..."
                        onChange={(val) => { setSearchValue({ ...searchValue, level: val.target.value }); setPage(1); }}
                        value={searchValue.level}
                      />
                    </td>
                    <td>
                      <Select
                        className="react-select-container"
                        classNamePrefix="react-select"
                        options={statusOptions}
                        isSearchable={false}
                        defaultValue={statusOptions[0]}
                        onChange={(item) => setSearchValue({ ...searchValue, status: item.value })}
                      />
                    </td>
                    <td></td>
                  </tr>

                  {
                    filteredLevels.slice((page - 1) * 20, Math.min(page * 20)).map((level, index) => (
                      <tr key={index}>
                        <td className="text-center">{level.id}</td>
                        <td>
                          <TableTextCell>
                            {level.language_code}
                          </TableTextCell>
                        </td>
                        <td>
                          <TableTextCell>
                            {level.level}
                          </TableTextCell>
                        </td>
                        <td>
                          <TableTextCell>
                            {level.status == 2 ? 'Published' : 'Draft'}
                          </TableTextCell>
                        </td>
                        <td className="table-action text-center">
                          <Row className="d-flex justify-content-around" style={{ margin: 'auto', width: 60 }}>
                            <Button className="p-0" color="none" onClick={() => handleEdit(level.id)}>
                              <Edit className="align-middle" size={18} />
                            </Button>
                            <Button className="p-0" color="none" onClick={() => handleDelete(level.id)}>
                              <Trash2 className="align-middle" size={18} />
                            </Button>
                          </Row>
                        </td>
                      </tr>
                    )
                    )}
                </tbody>
              </Table>

              <Row className="justify-content-center">
                <Pagination aria-label="Page navigation" size="lg">
                  <PaginationItem>
                    <PaginationLink previous onClick={() => handlePrev()} />
                  </PaginationItem>
                  {
                    pageCount > 0 ?
                      [...new Array(pageCount)].map((i, index) =>
                        <PaginationItem active={index + 1 == page ? true : false} key={index}>
                          <PaginationLink onClick={() => handlePage(index + 1)}>{index + 1}</PaginationLink>
                        </PaginationItem>)
                      :
                      null
                  }
                  <PaginationItem>
                    <PaginationLink next onClick={() => handleNext()} />
                  </PaginationItem>
                </Pagination>
              </Row>
              <Modal
                isOpen={open}
                toggle={() => toggle()}
                centered
              >
                <ModalHeader toggle={() => toggle()}>
                  Delete level
              </ModalHeader>
                <ModalBody className="text-center m-3">
                  <p className="mb-0">
                    Are you sure want to delete this level?
                </p>
                </ModalBody>
                <ModalFooter>
                  <Button color="secondary" onClick={() => toggle()}>
                    Cancel
                </Button>{" "}
                  <Button
                    color="danger"
                    onClick={() => handleConfirm()}
                  >
                    Delete
                </Button>
                </ModalFooter>
              </Modal>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </Container >
  );
}

export default Levels;
